export const LUNC = {
  name: 'uluna',
  decimals: 6,
  denom: 'LUNC',
  microdenom: 'uluna',
  img: '/assets/tokens/lunc.png',
  contract_addr: 'uluna',
  type: 'native',
  metadata: {},
  creator_address: null,
  cap: null,
  verified: true,
};

export const TERRA = {
  name: 'Terraport',
  decimals: 6,
  denom: 'TERRA',
  microdenom: 'TERRA',
  img: 'https://i.ibb.co/GtmvHT4/logo.png',
  contract_addr: 'terra1ex0hjv3wurhj4wgup4jzlzaqj4av6xqd8le4etml7rg9rs207y4s8cdvrp',
  type: 'cw20',
  metadata: {},
  creator_address: 'terra1m67k3ak5d05k7tzukstkujxptw0tnwt8cmf5kcxm6vwd403l4teshm07ls',
  cap: '1000000000000000',
  verified: true,
};

export const ROCKET_TERRA_PAIR_ADDR = 'terra17lveswhf4gk4l43k33zlz8j3r7kgdsp0tsmj5zunnuchsq547rmqqdr4j7';
