import { convertDenomToMicroDenom } from 'helper/utils';
import { MsgInstantiateContract } from 'cosmes/client';
import { walletState } from 'state/wallet-state';

export const createTokenMsg = ({
  initialSupply,
  image,
  supplyCap,
  name,
  symbol,
}: {
  initialSupply: string;
  image: string;
  supplyCap?: string;
  name: string;
  symbol: string;
}) => {
  const address = walletState.get.address()!;
  const msg = {
    decimals: 6,
    initial_balances: [
      {
        address: address,
        amount: convertDenomToMicroDenom(initialSupply, 6),
      },
    ],
    marketing: {
      logo: {
        url: image || '',
      },
      marketing: address,
    },
    mint: {
      minter: address,
      cap:
        supplyCap !== undefined
          ? convertDenomToMicroDenom(supplyCap, 6)
          : undefined,
    },
    name: name,
    symbol: symbol,
  };

  return new MsgInstantiateContract({
    sender: address,
    admin: address,
    codeId: BigInt(8266),
    msg,
    funds: [],
    label: `${name}_token_contract`,
  });
};
