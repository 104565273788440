import { FC, Suspense } from 'react';
import { CardModal } from '../components/modal';
import { Button, IconButton } from '../components/button';
import {
  closeWalletConnectModal,
  useAddress,
  walletState,
} from '../../state/wallet-state';
import { WalletType } from 'cosmes/wallet';
import { DEFAULT_CHAIN } from '../../consts/misc';
import { FaX } from 'react-icons/fa6';
import { capitalize, round } from 'lodash';
import { Planet, Star } from 'ui/components/planet';
import { BsCopy } from 'react-icons/bs';
import { copyToClipboard, getShortAddress } from 'helper/utils';
import { useTerraBalance } from 'api/rpc/balance';
import { useGetTradePairsQuery } from 'api/terraport/pairs';
import { FormattedNumber } from 'ui/components/formatted-number';
import { BuyCrypto } from 'ui/layout/header-buttons';
import Pending from 'ui/layout/pending';
import { useQuests } from 'api/terraport/quests';
import { Tooltip } from 'ui/components/tooltip';

const CurrentWallet = () => {
  const address = useAddress();
  const wallet = walletState.use.wallet();
  const controller = walletState.use.currentController();
  const balance = useTerraBalance();
  const pairs = useGetTradePairsQuery();
  const { data: questsData } = useQuests(address);

  const terraPriceDollars = pairs.data?.find(
    (pair) => pair.second_asset.microdenom === 'TERRA',
  )?.second_asset?.price_dollars;

  if (!wallet) return null;

  return (
    <div
      className="flex w-full flex-col items-start gap-4"
      style={{ zIndex: 1 }}>
      <div className="flex w-full min-w-0 flex-col items-center justify-between gap-2 sm:gap-4">
        <div className="relative size-[100px] overflow-clip rounded-2xl sm:mt-10 sm:size-[160px]">
          <img
            className="absolute -left-[84px] top-0 w-[200px] sm:-left-[128px] sm:top-0 sm:w-[300px]"
            src="assets/img/image 17.png"></img>
        </div>

        <div className="flex flex-row items-center justify-between gap-2">
          <div
            className="cursor-pointer text-ellipsis text-xl hover:underline hover:underline-offset-4"
            onClick={() =>
              window.open(
                `https://finder.terraport.finance/mainnet/address/${wallet.address}`,
                '_blank',
              )
            }>
            {getShortAddress(wallet.address)}
          </div>
          <IconButton
            className="w-[24px]"
            onClick={() => copyToClipboard(wallet.address)}>
            <BsCopy className="" />
          </IconButton>
        </div>

        <Button
          className=""
          style={{ width: 160 }}
          onClick={() => controller?.disconnect([DEFAULT_CHAIN])}>
          LOG OUT
        </Button>
      </div>
      <div className="flex w-full flex-col justify-between sm:flex-row md:mt-[20px]">
        <div
          className="flex flex-col items-start justify-start"
          style={{ flexGrow: 1 }}>
          <BuyCrypto />
          <p className="pt-2 font-bold">Balance:</p>
          <FormattedNumber
            value={balance * Number(terraPriceDollars) ?? 0}
            suffix="$"
            className="mt-2"
          />
          <p
            className="text-xl font-bold sm:text-3xl"
            style={{ color: '#b0e5fd' }}>
            <FormattedNumber
              value={balance}
              className="mt-1 font-bold"
              suffix="TERRA"
            />
          </p>
          <br></br>
          <p id="points-info" className="font-bold">
            {' '}
            Points:{' '}
            {round(
              Number(questsData?.quest_rewards ?? '0') +
                (questsData?.leaderboard_score ?? 0),
            )}
          </p>
          <Tooltip anchorSelect="#points-info">
            you can get points by daily activities and swapping{' '}
          </Tooltip>
        </div>
        {controller && (
          <div className="mt-5 flex flex-col items-start justify-start sm:mt-0">
            <p className="font-bold">Wallet:</p>
            <div className="mt-3 flex flex-row flex-wrap">
              <img
                className="mb-2 mr-4 size-12 rounded-2xl sm:size-16"
                src={`/assets/img/wallets/${controller.id}.webp`}
              />
              <div className="flex flex-col items-start justify-start">
                <p className="text-sm">
                  via {capitalize(wallet.id)}{' '}
                  {wallet.type === WalletType.WALLETCONNECT
                    ? 'Mobile'
                    : 'Extension'}
                </p>
                <div
                  className="mt-2 flex flex-row items-center justify-between"
                  style={{ width: 150 }}>
                  <span className="min-w-0 overflow-hidden text-ellipsis text-nowrap text-sm">
                    {wallet.address.slice(0, -4)}
                  </span>
                  <span className="text-sm">{wallet.address.slice(-4)}</span>
                  <IconButton
                    className="z-30 ml-2 w-20"
                    onClick={() => copyToClipboard(wallet.address)}>
                    <BsCopy className="" />
                  </IconButton>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* {buy && <Dialog as="div" className="relative z-10" onClose={() => setBuy(false)}>
      <div className="fixed inset-0 bg-black/50" />
      <div className="fixed inset-0 overflow-y-auto">
        <div className="flex min-h-dvh items-center justify-center text-center">
          <FaX className="cursor-pointer p-2 self-start" onClick={() => setBuy(false)} />
          <Dialog.Panel className="transition-all">
            <iframe
              className="border-none"
              height="660"
              width="660"
              title="Ramp Widget"
              src="https://guardarian.com/calculator/v1?partner_api_token=5aec0db8-5cef-4d90-a2c0-023d6a316c14&theme=blue&type=narrow&default_fiat_amount=50&crypto_currencies_list=%5B%7B%22ticker%22%3A%22LUNC%22%2C%22network%22%3A%22LUNC%22%7D%5D&fiat_currencies_list=%5B%7B%22ticker%22%3A%22EUR%22%2C%22network%22%3A%22EUR%22%7D%2C%7B%22ticker%22%3A%22USD%22%2C%22network%22%3A%22USD%22%7D%2C%7B%22ticker%22%3A%22GBP%22%2C%22network%22%3A%22GBP%22%7D%2C%7B%22ticker%22%3A%22TRY%22%2C%22network%22%3A%22TRY%22%7D%5D"
            />
          </Dialog.Panel>
        </div>
      </div>
    </Dialog>} */}
    </div>
  );
};

export const ConnectedWalletModal: FC = () => {
  const open = walletState.use.connectModalIsOpen();

  return (
    <CardModal
      open={open}
      onClose={closeWalletConnectModal}
      className="relative max-h-[95dvh] w-[90dvw] sm:w-[700px] sm:max-w-[90vw]"
      title={
        <div className="flex min-w-0 flex-col items-start p-2">
          <h1 className="text-start text-4xl font-bold">Profile</h1>
        </div>
      }
      subtitle={
        <FaX
          className="size-8 cursor-pointer self-start p-2 text-white"
          onClick={closeWalletConnectModal}
        />
      }>
      <div className="relative flex flex-col items-center rounded-b-3xl bg-[#0D1624]/50 bg-center bg-no-repeat p-4 sm:p-6">
        <div
          className="bg-[#0D1624]"
          style={{
            position: 'absolute',
            top: -80,
            bottom: 0,
            left: 0,
            right: 0,
            opacity: 0.7,
            height: 80,
            borderTopLeftRadius: 20,
            zIndex: -1,
            borderTopRightRadius: 20,
          }}></div>
        <div
          style={{
            position: 'absolute',
            top: -80,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: -2,
            overflow: 'clip',
            borderRadius: '20px',
          }}>
          <img
            src="/assets/img/wallets/bg.webp"
            alt="Background"
            className="absolute -left-[15%] -top-[10%] w-[130%] object-cover sm:-top-[30%]"
          />
        </div>

        <Planet
          src="PlanetMagenta01_RadialShadow_1024"
          top="0px"
          left="-50px"
          className="hidden sm:block"
        />
        <Planet
          src="PlanetYellow01_RadialShadow_1024"
          bottom="-40px"
          right="-90px"
          size="140px"
          className="hidden sm:block"
        />
        <Star size="18px" right="8%" top="25%" />
        <Star size="8px" right="6%" top="8%" />
        <Star size="8px" left="10%" bottom="40%" />
        <Star size="6px" left="6%" top="10%" />
        <Suspense fallback={<Pending small />}>
          <CurrentWallet />
        </Suspense>
      </div>
    </CardModal>
  );
};
