import { useQuery, useSuspenseQuery } from '@tanstack/react-query';
import axios from 'axios';
import { baseUrl } from './base';
import { DevPair, TradePair } from 'types/pairs';
import { useAddress, walletState } from 'state/wallet-state';
import { PairInfo } from 'types/pairs';

type TradePairsResponse = TradePair[];

// restituisce tutti i pairs approvati con relativi dati aggregati
export const useGetTradePairsQuery = () => {
  return useSuspenseQuery<TradePairsResponse>({
    queryKey: ['trade-pairs'],
    refetchInterval: 60000,
    queryFn: async () => {
      const resp = await axios.get<TradePairsResponse>(
        `${baseUrl}/trade-view-pair-data`,
      );
      return resp.data;
    },
  });
};

export const useGetTradePairQuery = (contract_addr?: string) => {
  return useQuery({
    enabled: !!contract_addr,
    queryKey: ['trade-pair', contract_addr],
    refetchInterval: 60000,
    queryFn: async () => {
      const resp = await axios.get<TradePair | null>(
        `${baseUrl}/degen/trade-view-pair-data/${contract_addr}`,
      );
      return resp.data;
    },
  });
};

// restituisce i pairs anche se non approvati creati dall'utente
export const useGetUserPairsQuery = (params?: { verified?: boolean }) => {
  const address = useAddress();
  return useSuspenseQuery<PairInfo[] | undefined>({
    queryKey: ['pairs', address, params?.verified],
    refetchInterval: 60000,
    queryFn: async () => {
      if (!address) return undefined;
      const resp = await axios.get<PairInfo[]>(`${baseUrl}/pairs`, {
        params: { creator_address: address, verified: params?.verified },
      });
      return resp.data;
    },
  });
};

// restituisce i pairs anche se non approvati creati dall'utente
export const useGetPairsQuery = (params?: { verified?: boolean }) => {
  return useSuspenseQuery<PairInfo[] | undefined>({
    queryKey: ['pairs', params?.verified],
    refetchInterval: 60000,
    queryFn: async () => {
      const resp = await axios.get<PairInfo[]>(`${baseUrl}/pairs`, {});
      return resp.data;
    },
  });
};

export const useGetPair = (contract_addr?: string) => {
  return useQuery<PairInfo>({
    queryKey: ['pair', contract_addr],
    enabled: !!contract_addr,
    refetchInterval: 30000,
    queryFn: async () => {
      const resp = await axios.get<DevPair>(
        `${baseUrl}/pairs/${contract_addr}`,
      );
      return resp.data;
    },
  });
};

export const useSuspenseGetPair = (contract_addr: string) => {
  return useSuspenseQuery<PairInfo>({
    queryKey: ['pair-susp', contract_addr],
    refetchInterval: 30000,
    queryFn: async () => {
      const resp = await axios.get<DevPair>(
        `${baseUrl}/pairs/${contract_addr}`,
      );
      return resp.data;
    },
  });
};

type VolumesResponse = {
  total_fee_24h: number;
  total_fee_delta_24h_percent: number;
  total_liquidity: number;
  total_liquidity_delta_24h_percent: number;
  total_volume_7d: number;
  total_volume_15d: number;
  total_volume_24h: number;
  total_volume_30d: number;
  total_volume_delta_7d_percent: number;
  total_volume_delta_15d_percent: number;
  total_volume_delta_24h_percent: number;
  total_volume_delta_30d_percent: number;
};

// restituisce i pairs anche se non approvati creati dall'utente
export const useGetTradeTotalsQuery = () => {
  return useSuspenseQuery<VolumesResponse>({
    queryKey: ['trade-totals'],
    refetchInterval: 60000,
    queryFn: async () => {
      const resp = await axios.get<VolumesResponse>(`${baseUrl}/volumes`);
      return resp.data;
    },
  });
};

export const useGetPairByAssets = (
  from_contract?: string,
  to_contract?: string,
) => {
  return useQuery<{ exists: boolean; pair: PairInfo | null }>({
    queryKey: ['exists', from_contract, to_contract],
    refetchInterval: 60000,
    enabled: !!(from_contract && to_contract),
    queryFn: async () => {
      const resp = await axios.get<{ exists: boolean; pair: PairInfo | null }>(
        `${baseUrl}/pairs/exists?token0=${from_contract}&token1=${to_contract}`,
      );
      return resp.data;
    },
  });
};

export const useGetDevFarmingPairs = (search?: string) => {
  const address = useAddress();
  return useQuery<PairInfo[]>({
    queryKey: ['dev-farm-pairs', search, address],
    refetchInterval: 30000,
    enabled: !!address,
    queryFn: async () => {
      const resp = await axios.get<PairInfo[]>(`${baseUrl}/farming/pairs`, {
        params: {
          search,
        },
        headers: {
          Authorization: `Bearer ${walletState.get.jwt()}`,
        },
      });
      return resp.data;
    },
  });
};

//POST https://service.terraport.finance/development/pairs/terra1t5a2l7ulvm34huy2wqpxxy4rytfkz8fs8kvmm76gflxatxucy3rqmkhuue/update con il token e body {} questo serve in development post creazione pair per fare sapere subito al be che è stato creato
export const announceCreatedPair = async (pairAddr: string) => {
  const res = await axios.post(
    `${baseUrl}/development/pairs/${pairAddr}/update`,
    {},
    {
      headers: {
        Authorization: `Bearer ${walletState.get.jwt()}`,
      },
    },
  );
  return res.data;
};

export const useGetDevPair = (address: string) => {
  return useQuery<DevPair>({
    queryKey: ['dev-pair', address],
    refetchInterval: 30000,
    queryFn: async () => {
      const resp = await axios.get<DevPair>(`${baseUrl}/pairs/${address}`, {
        headers: {
          Authorization: `Bearer ${walletState.get.jwt()}`,
        },
      });
      return resp.data;
    },
  });
};
