import { FC } from 'react';
import { Navigate } from 'react-router';

type Props = {
  element: JSX.Element;
  hidden?: () => boolean;
};

export const PrivateRoute: FC<Props> = ({ hidden, element }) => {
  if (hidden?.()) {
    return <Navigate to="/" />;
  }
  return element;
};
