import { useQuery } from '@tanstack/react-query';
import { baseUrl } from 'api/terraport/base';
import axios from 'axios';
import { millisecondsInMinute } from 'date-fns/constants';
import { map } from 'lodash';
import { FC } from 'react';
import Marquee from 'react-fast-marquee';
import { Link } from 'react-router-dom';

type TrendingResponse = {
  contract_address: string;
  position: number;
  first_asset: {
    contract_address: string;
    denom: string;
    img: string;
    microdenom: string;
  };
  second_asset: {
    contract_address: string;
    denom: string;
    img: string;
    microdenom: string;
  };
}[];

export const TrendingPairs: FC = () => {
  const { data } = useQuery<TrendingResponse>({
    queryKey: ['trending'],
    queryFn: async () => {
      const { data } = await axios.get(
        `${baseUrl}/trade-view-pair-data/trending`,
      );
      return data;
    },
    refetchInterval: 5 * millisecondsInMinute,
  });

  return (
    <div className="mt-0 flex w-full animate-fade flex-row items-center justify-start bg-[#522257] text-white animate-delay-150 landscape:hidden landscape:md:flex">
      <div className="relative flex h-full items-center gap-2 justify-self-start bg-[#9E278C] py-2 pl-6 pr-1 text-lg font-bold">
        <img src="/assets/svg/trending.svg" className="size-4" />
        <p className="md:center hidden text-sm">TRENDING</p>
        <div
          className="absolute -right-[38px] bottom-0 top-0 z-[3] w-[40px] self-stretch bg-[#9E278C]"
          style={{ clipPath: 'url(#card-end)' }}
        />
      </div>
      <Marquee
        className="ml-6"
        gradient
        gradientWidth={150}
        gradientColor="#522257"
        speed={40}
        pauseOnHover
        play>
        {map(data, (pair) => (
          <Link
            to={{ pathname: `/trade/${pair.contract_address}` }}
            key={`${pair.first_asset.denom}${pair.second_asset.denom}`}
            className="relative flex flex-row items-center rounded-lg px-4 py-1 text-white no-underline transition-all hover:bg-[#9E278C]">
            <p className="font-bold">#{pair.position}</p>
            <img
              src={pair.first_asset.img}
              className="ml-2 mr-5 size-5 rounded-full"
            />
            <img
              src={pair.second_asset.img}
              className="absolute left-12 mx-2 size-5 rounded-full"
            />
            <p>{pair.first_asset.denom}/</p>
            <p className="text-white/40">{pair.second_asset.denom}</p>
          </Link>
        ))}
      </Marquee>
    </div>
  );
};
