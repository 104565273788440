import { Link } from 'react-router-dom';
import clsx from 'clsx/lite';
const TerraLogo = '/assets/img/terra.png';

export const Logo = ({
  small = false,
  className,
}: {
  small?: boolean;
  className?: string;
}) => (
  <Link
    to={'/'}
    className={clsx(
      'flex items-center gap-2 text-accent1 no-underline',
      className,
    )}>
    <img
      src={TerraLogo}
      width={small ? 24 : 32}
      height={small ? 24 : 32}
      alt="Terra Logo"
    />
    <p className={clsx(small && 'text-small', 'hidden font-logo md:block')}>
      TERRAPORT
    </p>
  </Link>
);
