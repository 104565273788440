import { Transition } from '@headlessui/react';
import { Link, useLocation } from 'react-router-dom';
import { ComponentProps, FC, PropsWithChildren } from 'react';
import { MultiNavItem, NavItem } from 'types/routes';
import clsx from 'clsx/lite';
import { Logo } from 'ui/components/logo';
import { HeaderButtons } from './header-buttons';
import { TrendingPairs } from 'ui/components/trending';
import { useAppRoutes } from 'hooks/use-routes';
import { SearchBar } from 'ui/components/search-bar';

type NavLinkProps = {
  route: NavItem | MultiNavItem;
  index?: number;
  linkClassName?: string;
  as?: ComponentProps<typeof Transition>['as'];
  current?: boolean;
};

const NavLink = ({ route, index = 0, linkClassName, current }: NavLinkProps) => {
  if (route.hidden?.()) {
    return null;
  }
  return (
    <Link
      to={route.multipath ? route.paths[0]?.pathname : route.pathname}
      className={clsx(
        'animate-fade-right transition-all',
        'px-4 py-1 text-white no-underline',
        current && 'rounded-full bg-accent-btn font-semibold !text-text-accent',
        current && route.multipath && 'bg-accent2',
        linkClassName,
      )}
      style={{ animationDelay: `${50 * index}ms` }}
      onClick={(e) => {
        if ((route as NavItem).onClick) {
          e.stopPropagation();
          e.preventDefault();
          (route as NavItem).onClick?.();
        }
      }}
      target={route.openOtherPage ? '_blank' : '_self'}>
      {route.title}
    </Link>
  );
};

export const Header = ({ children }: PropsWithChildren) => {
  const { pathname } = useLocation();
  const { routes, currentIndex } = useAppRoutes();
  return (
    <>
      <div className="sticky top-0 z-10 flex w-dvw animate-fade-down flex-row flex-wrap items-center justify-between">
        <div className="grid h-fit w-full grid-cols-[auto_1fr_auto] items-center justify-between gap-2 bg-background3 p-4 px-4 !text-white md:grid-cols-[auto_1fr_auto] md:px-8 lg:grid-cols-[2fr_2fr_1fr_1fr]">
          <Logo className="mr-auto animate-fade animate-delay-75" />
          <nav className="mx-auto hidden animate-fade flex-row gap-2 animate-delay-150 lg:flex">
            {routes.map((route, index) => (
              <NavLink key={route.title} route={route} index={index} current={currentIndex === index} />
            ))}
          </nav>
          <SearchBar />
          <HeaderButtons />
        </div>
        {currentIndex >= 0 && !!routes[currentIndex]?.multipath && (
          <div className="mt-0 hidden w-full animate-fade items-center justify-center gap-2 bg-background1 py-3 animate-delay-75 lg:flex">
            {currentIndex >= 0 &&
              routes[currentIndex]?.multipath &&
              (routes[currentIndex] as MultiNavItem).paths.map((route, index) => (
                <NavLink
                  index={index}
                  key={route.title}
                  route={route}
                  current={pathname.startsWith(route.pathname)}
                  linkClassName="text-sm"
                />
              ))}
          </div>
        )}
        {(pathname === '/' || pathname.startsWith('/trade') || pathname.startsWith('/swap')) && (
          <TrendingPairs />
        )}
        {children}
      </div>
    </>
  );
};
