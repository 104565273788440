export function convertMicroDenomToDenom(amount: string | number, decimal = 6) {
  if (typeof amount === 'string') {
    amount = Number(amount);
  }
  amount = amount / 10 ** decimal;
  return isNaN(amount) ? 0 : amount;
}

export function convertDenomToMicroDenom(amount: string | number, decimal = 6) {
  if (typeof amount === 'string') {
    amount = Number(amount);
  }
  amount = amount * 10 ** decimal;
  return isNaN(amount) ? '0' : String(amount).split('.')[0];
}

export function convertFromMicroDenom(denom: string) {
  return denom?.substring(1).toUpperCase();
}

export function convertToFixedDecimals(amount: string | number) {
  if (typeof amount === 'string') {
    amount = Number(amount);
  }
  if (amount > 0.01) {
    return amount.toFixed(2);
  } else return String(amount);
}

export function float2int(value: number) {
  return parseInt(value.toFixed(0));
}

export function parseDecimalFloat(value: number, count = 3) {
  return parseFloat(value.toFixed(count));
}

export const zeroVotingCoin = {
  amount: '0',
  denom: 'ucredits',
};

export const zeroStakingCoin = {
  amount: '0',
  denom: import.meta.env.VITE_PUBLIC_STAKING_DENOM || 'ujuno',
};

export const numberWithCommas = (x: string | number, digits = 3) => {
  if (isEmpty(x)) return '0';
  return Number(x).toLocaleString(undefined, { maximumFractionDigits: digits });
};

export const parseNumber = (n: number, digits = 3) => {
  if (isNaN(n)) return 0;
  return parseInt((n * 10 ** digits).toString()) / 10 ** digits;
};

export const getShortAddress = (address: string, short = false) => {
  if (isEmpty(address)) return '';
  if (address.length < 10) {
    return address;
  }
  return (
    address.slice(0, short ? 5 : 10) +
    '...' +
    address.slice(address.length - 5, address.length)
  );
};

export const isValid = (address: string) => {
  // check the string format:
  // - starts with "terra1"
  // - length == 44 ("terra1" + 38)
  // - contains only numbers and lower case letters
  return /(terra1[a-z0-9]{38})/g.test(address);
};

export const isEmpty = (value: unknown) =>
  value === undefined ||
  value === null ||
  (typeof value === 'object' && Object.keys(value).length === 0) ||
  (typeof value === 'string' && value.trim().length === 0);

export const parseErrorMsg = (errMsg: string) => {
  if (isEmpty(errMsg)) return '';
  let returStr = '';
  const startPos = JSON.stringify(errMsg).search('submessages:');
  if (startPos >= 0) {
    const subStr = errMsg.substring(startPos + 12, errMsg.length);
    returStr = subStr;
  } else returStr = errMsg;
  return returStr;
};

export const getSymbol = (key: string) => {
  switch (key) {
    case 'LUNC':
      return 'uluna';
    case 'USTC':
      return 'uusd';
    default:
      return '';
  }
};

export const hasTaxToken = (contract_addr: string) =>
  !contract_addr.startsWith('terra') && !contract_addr.startsWith('ibc');

export const copyToClipboard = (data: string) => {
  if (navigator.clipboard && window.isSecureContext) {
    // navigator clipboard api method'
    return navigator.clipboard.writeText(data);
  } else {
    const textField = document.createElement('textarea');
    textField.innerText = data;
    textField.style.position = 'fixed';
    textField.style.left = '-999999px';
    textField.style.top = '-999999px';
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
  }
};

export const formatNumbers = (num, decimals = 2) => {
  return Intl.NumberFormat(navigator.languages, {
    maximumFractionDigits: decimals,
  }).format((num || 0).toFixed(decimals));
};

export const formatPositiveInteger = (value: number) => {
  let result = Math.trunc(value);
  if (result <= 0) result = 0; //il trunc potrebbe restituire -0
  return Intl.NumberFormat(navigator.languages, {
    minimumIntegerDigits: 2,
  }).format(result);
};
