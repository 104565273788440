import {
  OrderInfo,
  Product,
  confirmOrder,
  orderProducts,
  postOrderError,
  useGetProducts,
} from 'api/terraport/payments';
import { keyBy } from 'lodash';
import { useEffect } from 'react';
import { createStore } from 'zustand-x';
import { postTx } from './contract';
import { MsgExecuteContract } from 'cosmes/client';
import { CreatePairParams } from 'types/pairs';
import { invalidateQueries } from './providers/query';
import { createPairMsg } from 'api/messages/swap';
import { createTokenMsg } from 'api/messages/token';
import { toast } from 'react-toastify';
import { announceCreatedToken } from 'api/terraport/tokens';
import { CreateTokenParams } from 'ui/pages/development/token/create-token-modal';
import { CODE_ID, IS_DEV } from 'consts/misc';
import { announceCreatedPair } from 'api/terraport/pairs';
import { CreateFarmParams } from 'ui/pages/development/farm/create-farm-modal';
import { activateFarmMsg, createFarmMsg } from 'api/messages/farm';
import { postUpdateDevFarm } from 'api/terraport/farming';
import { addLiquidityMsgs, calcLiquidityTax } from 'api/messages/liquidity';
import { convertDenomToMicroDenom } from 'helper/utils';

export enum OP_TYPE {
  TEST = 1,
  DEV_PAIR_CW20_NATIVE = 2,
  DEV_PAIR_CW20_CW20 = 3,
  DEV_TOKEN = 4,
  DEV_FARM = 5,
}

export type Payloads = {
  [OP_TYPE.TEST]: null;
  [OP_TYPE.DEV_PAIR_CW20_NATIVE]: CreatePairParams & { address: string };
  [OP_TYPE.DEV_PAIR_CW20_CW20]: CreatePairParams & { address: string };
  [OP_TYPE.DEV_TOKEN]: CreateTokenParams & { address: string };
  [OP_TYPE.DEV_FARM]: CreateFarmParams & { address: string };
};

const createPair = async (
  payload: Payloads[OP_TYPE.DEV_PAIR_CW20_CW20],
): Promise<void> => {
  if (payload.firstAsset && payload.secondAsset) {
    try {
      const instantiateMsg = createPairMsg({
        firstToken: payload.firstAsset,
        secondToken: payload.secondAsset,
      });
      const { txData } = await postTx([instantiateMsg]);
      const pairAddr = txData?.txResponse.events
        .find(
          (value) =>
            value.type === 'instantiate' &&
            value.attributes.find(
              (value) =>
                value.key === 'code_id' && value.value === CODE_ID.PAIR,
            ),
        )
        ?.attributes.find((value) => value.key === '_contract_address')?.value;
      if (pairAddr === undefined) {
        toast.error('Creation Failed');
        return Promise.reject('Creation Failed');
      }
      const firstValue = payload.firstLiquidity;
      const secondValue = payload.secondLiquidity;
      if (firstValue > 0 && secondValue > 0) {
        await postTx(
          addLiquidityMsgs({
            pairContract: pairAddr,
            fromAsset: payload.firstAsset,
            toAsset: payload.secondAsset,
            fromAmount: firstValue,
            toAmount: secondValue,
            isV3: false,
          }),
          {
            tax: await calcLiquidityTax(
              firstValue,
              payload.firstAsset,
              secondValue,
              payload.secondAsset,
            ),
          },
        );
      }
      await announceCreatedPair(pairAddr);
      invalidateQueries([{ queryKey: ['pairs'] }]);
    } catch (error) {
      console.error(error);
      return Promise.reject(error);
    }
  }
};

const operationCallbacks = {
  [OP_TYPE.TEST]: async (payload: Payloads[OP_TYPE.TEST]): Promise<void> => {
    await new Promise((resolve) => {
      setTimeout(() => resolve(payload), 1000);
    });
  },
  [OP_TYPE.DEV_PAIR_CW20_NATIVE]: createPair,
  [OP_TYPE.DEV_PAIR_CW20_CW20]: createPair,
  [OP_TYPE.DEV_TOKEN]: async (
    payload: Payloads[OP_TYPE.DEV_TOKEN],
  ): Promise<void> => {
    try {
      const instantiateMsg = createTokenMsg({
        image: payload.image,
        initialSupply: payload.initialSupply,
        name: payload.name,
        symbol: payload.denom,
        supplyCap: payload.supplyCap,
      });
      const { txData } = await postTx([instantiateMsg]);
      const tokenAddr = txData?.txResponse.events
        .find((value) => value.type === 'instantiate')
        ?.attributes.find((value) => value.key === '_contract_address')?.value;
      if (tokenAddr === undefined) {
        toast.error('Creation Failed');
      } else {
        await announceCreatedToken({
          contract_addr: tokenAddr,
          creator_address: payload.address,
          denom: payload.denom,
          img: payload.image,
          name: payload.name,
          cap: payload.supplyCap
            ? convertDenomToMicroDenom(payload.supplyCap)
            : undefined,
        });
      }
      invalidateQueries([{ queryKey: ['tokens'] }]);
    } catch (error) {
      console.error(error);
      return Promise.reject(error);
    }
  },
  [OP_TYPE.DEV_FARM]: async (
    payload: Payloads[OP_TYPE.DEV_FARM],
  ): Promise<void> => {
    try {
      const { txData } = await postTx([createFarmMsg(payload)]);
      const farmAddr = txData?.txResponse.events
        .find((value) => value.type === 'instantiate')
        ?.attributes.find((value) => value.key === '_contract_address')?.value;
      if (farmAddr === undefined) {
        toast.error('Creation Failed');
        Promise.reject('Creation Failed');
      } else {
        await postTx([
          activateFarmMsg({
            reward_token: payload.rewardToken,
            farm_address: farmAddr,
            days: payload.days,
            amount: payload.rewardAmount,
          }),
        ]);
        await postUpdateDevFarm(farmAddr);
      }
      invalidateQueries([{ queryKey: ['dev-farming'] }]);
    } catch (error) {
      console.error(error);
      return Promise.reject(error);
    }
  },
};

export enum OP_PROGRESS {
  ORDER_POSTED,
  PAYMENT_SIGNED,
  PAYMENT_CONFIRMED,
  COMPLETED,
}

export type Operation = {
  payload: Payloads[OP_TYPE];
  info: OrderInfo;
  type: OP_TYPE;
  payment_hash?: string;
  state: OP_PROGRESS;
  error?: string;
};

export type PaidOpState = {
  ops: Record<string, Operation>;
  products: Record<number, Product>;
  current: string | null;
};

export const opState = createStore('operations')<PaidOpState>(
  {
    products: {},
    ops: {},
    current: null,
  },
  {
    persist: {
      version: 1,
      // enabled: true,
      // onRehydrateStorage: () => (state, error) => {
      //   if (!error) {
      //     // forEach(state?.ops, async (op, id) => await operationFlow(id));
      //   }
      // },
      // merge: (persisted = {}, current) => ({
      //   ops: { ...current.ops, ...pickBy(persisted?.ops, op => op.state !== OP_PROGRESS.COMPLETED), },
      //   current: null,
      //   products: { ...persisted?.products ?? {}, ...current.products,}
      // })
    },
  },
)
  .extendSelectors(({ products, ops, current }) => ({
    operation: (id: string) => ops[id],
    product: (id: OP_TYPE) => products[id],
    currentOp: () => (current ? ops[current] : null),
  }))
  .extendActions((set, get) => ({
    operation: async (id: string, order: Partial<Operation>) => {
      const current = get.ops()[id];
      set.ops({ ...get.ops(), [id]: { ...(current ?? {}), ...order } });
    },
  }))
  .extendActions((set, get) => ({
    error: async (id: string, error: string) => {
      const op = get.ops()[id];
      postOrderError(id, op, error);
      set.operation(id, { error });
    },
  }))
  .extendActions((set, get) => ({
    newOperation: async <T extends OP_TYPE>(type: T, payload: Payloads[T]) => {
      const { data } = await orderProducts([IS_DEV ? OP_TYPE.TEST : type]);
      const newOp: Operation = {
        state: OP_PROGRESS.ORDER_POSTED,
        type,
        info: data,
        payload,
      };
      set.operation(data.order.hash, newOp);
      set.current(data.order.hash);
      return data.order.hash;
    },
    payOperation: async (id: string) => {
      const operation = get.ops()[id];
      if (operation?.state === OP_PROGRESS.ORDER_POSTED) {
        const { memo, ...msg } = operation.info.payment;
        const { hash, txData } = await postTx(
          [new MsgExecuteContract({ ...msg, funds: [] })],
          {
            memo,
            toasts: {
              success: 'Payment completed',
              error: 'Payment transaction failed, please try again',
            },
          },
        );
        if (txData) {
          set.operation(id, {
            payment_hash: hash,
            state: OP_PROGRESS.PAYMENT_SIGNED,
          });
        }
        return !!txData;
      }
    },
    confirmPayment: async (id: string) => {
      const operation = get.ops()[id];
      if (
        operation.state === OP_PROGRESS.PAYMENT_SIGNED &&
        operation.payment_hash
      ) {
        const { data: response } = await confirmOrder(
          id,
          operation.payment_hash,
        );
        if (response.status === 'PAID') {
          set.operation(id, { state: OP_PROGRESS.PAYMENT_CONFIRMED });
          return true;
        } else {
          set.error(id, 'There was an error during payment confirmation');
        }
      }
    },
    executeOperation: async (id: string) => {
      const operation = get.ops()[id];
      if (operation.state === OP_PROGRESS.PAYMENT_CONFIRMED) {
        return await operationCallbacks[operation.type](operation.payload).then(
          () => set.operation(id, { state: OP_PROGRESS.COMPLETED }),
        );
      }
    },
  }));

// opState.store.subscribe(console.info);

export const useProducts = () => {
  const { data } = useGetProducts();
  useEffect(() => {
    if (data) {
      opState.set.products(keyBy(data, (p) => p.id));
    }
  }, [data]);
};

export async function executePaidOperation<T extends OP_TYPE>(
  type: T,
  payload: Payloads[T],
): Promise<string> {
  const id = await opState.set.newOperation(type, payload);
  return id;
}
