import clsx from 'clsx';
import { FC, ReactNode } from 'react';
import { FaChevronDown } from 'react-icons/fa6';
import { Asset, PairInfo } from 'types/pairs';

export const PairSelectContent: FC<{ pair: PairInfo | null }> = ({ pair }) =>
  pair ? (
    <>
      <div className="flex items-center">
        <img
          src={pair.first_token.img}
          className="z-[1] ml-1 !h-8 !w-8 rounded-full bg-background4 p-1"
        />
        <img
          src={pair.second_token.img}
          className="relative -left-3 ml-1 !h-7 !w-7 rounded-full bg-background4 p-1"
        />
      </div>
      <div className="flex w-full flex-col gap-1 !text-[10px]">
        <div className="flex w-full flex-row items-center gap-1">
          <p
            className={clsx(
              'min-w-0 text-ellipsis whitespace-nowrap text-nowrap font-logo font-bold',
              !pair.verified && 'text-white/60',
            )}>
            {pair.first_token.denom ?? pair.first_token.name}
          </p>
          <img src="/assets/svg/reverse-btn.svg" className="size-4" />
          <p
            className={clsx(
              'min-w-0 text-ellipsis whitespace-nowrap text-nowrap font-logo font-bold',
              !pair.verified && 'text-white/60',
            )}>
            {pair.second_token.denom ?? pair.second_token.name}
          </p>
        </div>
        <div className="flex flex-row items-start gap-1">
          <p
            className={clsx(
              'center h-4 w-fit rounded-full bg-gradient-to-l from-theme-purple to-theme-purple2 px-2 text-xs font-bold',
            )}>
            {pair.type}
          </p>
          <p
            className={clsx(
              'center h-4 w-fit rounded-full bg-gradient-to-r via-30% px-2 text-xs font-bold',
              pair.first_token.type === 'native'
                ? 'from-theme-purple2 via-theme-purple2'
                : 'from-gr1 via-gr1',
              pair.second_token.type === 'native'
                ? 'to-theme-purple'
                : 'to-gr2',
            )}>
            {pair.first_token.type}/{pair.second_token.type}
          </p>
        </div>
      </div>
      <div className="center !min-h-6 !min-w-6 rounded-full bg-theme-yellow1 text-black">
        <FaChevronDown />
      </div>
    </>
  ) : null;

type AssetChipProps = {
  asset: Asset | null;
  children: ReactNode;
};
export const AssetChip = ({ asset, children }: AssetChipProps) => (
  <div className="grid w-full grid-cols-[2fr_3fr] rounded-2xl bg-white/10 text-white">
    <div className="flex flex-row items-center justify-start gap-2 rounded-l-2xl bg-background4 px-2">
      <img src={asset?.img} className="!h-8 !w-8 rounded-full" />
      <div className="mr-auto flex flex-col">
        <p className="font-logo text-sm">{asset?.denom}</p>
        <p className="text-[10px] text-white/80">{asset?.name}</p>
      </div>
    </div>
    <div className="flex h-full flex-col items-end justify-between gap-1 rounded-r-2xl p-2 px-4">
      {children}
    </div>
  </div>
);
