import { ComponentProps, FC, useCallback, useMemo, useState } from 'react';
import { Dialog, DialogPanel, DialogBackdrop } from '@headlessui/react';
import { Card } from './card';
import clsx from 'clsx';
import { FaX } from 'react-icons/fa6';

// export const Modal: FC<{open: boolean; onClose: () => void, children: ReactNode}> = ({open, onClose, children}) => {

//   return (
//     <Transition appear show={open} as={Fragment}>
//       <Dialog open={open} onClose={onClose}>
//         <Dialog.Panel>
//           <Dialog.Title>Deactivate account</Dialog.Title>
//           <Dialog.Description>
//           This will permanently deactivate your account
//           </Dialog.Description>
//           {children}
//           <p>
//           Are you sure you want to deactivate your account? All of your data
//           will be permanently removed. This action cannot be undone.
//           </p>

//           <button onClick={onClose}>Deactivate</button>
//           <button onClick={onClose}>Cancel</button>
//         </Dialog.Panel>
//       </Dialog>
//     </Transition>
//   );
// };

export const useModal = () => {
  const [open, setOpen] = useState(false);
  const openModal = useCallback(() => setOpen(true), []);
  const closeModal = useCallback(() => setOpen(false), []);
  return useMemo(
    () => ({ open, openModal, closeModal }),
    [open, openModal, closeModal],
  );
};

export const CardModal: FC<
  ComponentProps<typeof Card> & {
    open: boolean;
    onClose: () => void;
    animation?: string;
  }
> = ({ open = false, onClose, animation = 'animate-fade', ...cardProps }) => {
  return (
    <Dialog
      open={open}
      as="div"
      className="relative z-10"
      onClose={onClose}
      transition>
      <DialogBackdrop
        className={clsx(
          'fixed inset-0 bg-black/30 backdrop-blur-2xl',
          animation,
        )}>
        {/* <QuickSwapButton className='!flex !lg:hidden'/> */}
      </DialogBackdrop>
      <div className="fixed inset-0 overflow-y-auto">
        <div className="flex min-h-dvh items-center justify-center text-center">
          <DialogPanel className={clsx('transform transition-all', animation)}>
            <Card
              subtitle={
                <FaX
                  className="size-8 cursor-pointer self-start p-2 text-white"
                  onClick={onClose}
                />
              }
              {...cardProps}
            />
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
};
