import { Transition } from '@headlessui/react';
import clsx from 'clsx/lite';
import { map, throttle } from 'lodash';
import { ComponentProps, Fragment, useLayoutEffect, useState } from 'react';
import { useMDBreakpoint } from 'helper/responsive';

export function useWindowScroll() {
  const [scroll, setState] = useState<number>(0);

  useLayoutEffect(() => {
    const handleScroll = throttle((e) => {
      setState(e.target.scrollTop);
    }, 250);
    const element = document.getElementById('layout');
    if (element) {
      element.addEventListener('scroll', handleScroll);
      return () => {
        document.body.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  return scroll;
}

export function useMouse() {
  const [state, setState] = useState({
    x: 0,
    y: 0,
  });

  useLayoutEffect(() => {
    const handleMouseMove = throttle((event) => {
      setState({
        x: event.pageX,
        y: event.pageY,
      });
    }, 100);
    document.addEventListener('mousemove', handleMouseMove);
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return state;
}

export const Planet = ({
  src,
  top,
  bottom,
  left,
  right,
  size = '80px',
  className,
  style,
  ...props
}: Omit<ComponentProps<'img'>, 'ref'> & {
  top?: string;
  bottom?: string;
  left?: string;
  right?: string;
  size?: string;
}) => {
  return (
    <Transition
      as={'img'}
      appear
      show
      enter="transform transition delay-150 duration-500"
      enterFrom="opacity-0 rotate-[-120deg] scale-50"
      enterTo="opacity-100 rotate-0 scale-100"
      leave="transform duration-200 transition ease-in-out"
      leaveFrom="opacity-100 rotate-0 scale-100 "
      leaveTo="opacity-0 scale-95 "
      className={clsx('pointer-events-none absolute', className)}
      style={{
        transition: 'transform 0.3s ease-out',
        top,
        bottom,
        left,
        right,
        height: size,
        width: size,
        ...style,
      }}
      src={`/assets/img/planets/${src}.webp`}
      {...props}></Transition>
  );
};

export const Star = ({
  top,
  bottom,
  left,
  right,
  size = '8px',
  small = false,
  style,
  ...props
}: ComponentProps<'svg'> & {
  top?: string;
  bottom?: string;
  left?: string;
  right?: string;
  size?: string;
  small?: boolean;
}) => {
  return (
    <Transition
      as={Fragment}
      appear
      show
      enter="transform transition delay-150 duration-300"
      enterFrom="opacity-0 rotate-[-120deg] scale-50"
      enterTo="opacity-100 rotate-0 scale-100"
      leave="transform duration-200 transition ease-in-out"
      leaveFrom="opacity-100 rotate-0 scale-100 "
      leaveTo="opacity-0 scale-95 ">
      {small ? (
        <div
          className="pointer-events-none absolute rounded-full bg-white"
          style={{
            transition: 'transform 0.5s ease-out',
            top,
            bottom,
            left,
            right,
            height: size,
            width: size,
            filter: `drop-shadow(0 0 calc(${size} / 3) white)`,
            ...style,
          }}
        />
      ) : (
        <svg
          width="6"
          height="10"
          viewBox="0 0 6 10"
          fill="none"
          className={'pointer-events-none absolute'}
          style={{
            transition: 'transform 0.5s ease-out',
            top,
            bottom,
            left,
            right,
            height: size,
            width: size,
            filter: `drop-shadow(0 0 calc(${size} / 3) white)`,
            ...style,
          }}
          xmlns="http://www.w3.org/2000/svg"
          {...props}>
          <path
            d="M5.28998 4.91002C4.11998 5.20002 3.23997 6.15002 3.02997 7.33002L2.68994 9.23003L2.34998 7.33002C2.13998 6.15002 1.24997 5.20002 0.0899658 4.91002C1.25997 4.62002 2.13998 3.67002 2.34998 2.49002L2.68994 0.590027L3.02997 2.49002C3.23997 3.67002 4.12998 4.62002 5.28998 4.91002Z"
            fill="#F6F6F6"
          />
        </svg>
      )}
    </Transition>
  );
};

const planetNames = [
  'PlanetMagenta01_RadialShadow_1024',
  'PlanetCrater01_RadialShadow_1024',
  'PlanetCrater02_RadialShadow_1024',
  'PlanetBlue01_RadialShadow_1024',
  'PlanetYellow01_RadialShadow_1024',
  'PlanetRing01_RadialShadow_1024',
  'PlanetSpaceShip_RadialShadow_1024',
  'SpaceShip01C_RadialShadow_1024',
];

const PLANETS_COUNT = 10;
const planets = map(Array(PLANETS_COUNT), (_x, i) => {
  const baseMult = Math.random();
  return {
    mult: -0.15 * baseMult,
    size: `${20 + baseMult * 80}px`,
    planet: planetNames[i % planetNames.length],
    top: `${(PLANETS_COUNT - 2) * (i + Math.random()) + 10}%`,
    left: i % 2 ? `${10 * Math.random() + 5}vw` : undefined,
    right: i % 2 ? undefined : `${10 * Math.random() - 5}vw`,
  };
});

const STARS_COUNT = 50;
const stars = map(Array(STARS_COUNT), () => {
  const small = Math.random() > 0.5;
  const baseMult = Math.random();
  return {
    mult: -(3 * baseMult + 0.1) * 0.02,
    small,
    size: small ? `${2 * (1 + baseMult)}px` : `${4 * (1 + baseMult)}px`,
    top: `${90 * Math.random() + 5}%`,
    left: `${Math.random() * 100}%`,
  };
});

export const ParallaxBackground = () => {
  const { x, y } = useMouse();
  const scroll = useWindowScroll();
  const small = useMDBreakpoint();
  return (
    <>
      {stars.map((star, index) => (
        <Star
          key={`${index}${star.top}`}
          top={star.top}
          small={star.small}
          left={star.left}
          size={star.size}
          style={{
            transform: `translate(${star.mult * x}px, ${star.mult * (y + scroll)}px)`,
          }}
        />
      ))}
      {!small &&
        planets.map((planet, index) => (
          <Planet
            key={`${index}${planet.planet}`}
            size={planet.size}
            top={planet.top}
            src={planet.planet}
            left={planet.left}
            right={planet.right}
            style={{
              transform: `translate(${planet.mult * x}px, ${planet.mult * (y + scroll)}px)`,
            }}
          />
        ))}
    </>
  );
};
