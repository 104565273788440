import { toast } from 'react-toastify';
import { ReactNode } from 'react';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { FaCopy } from 'react-icons/fa6';
import { copyToClipboard } from 'helper/utils';

export const txSuccessToast = ({
  children,
  message,
  tx,
}: {
  children: ReactNode;
  message: string;
  tx: string;
}) => {
  return toast(
    <div className="flex flex-col items-start gap-1">
      <div className="flex w-[200px] min-w-0 flex-row items-center justify-start gap-1 overflow-hidden text-ellipsis text-nowrap">
        {children}
      </div>
      <a
        href={`https://finder.terraport.finance/classic/tx/${tx}`}
        target="_blank"
        className="flex flex-row items-center gap-1"
        rel="noreferrer">
        {message}
        <FaExternalLinkAlt />
      </a>
    </div>,
    {
      className: 'custom success',
    },
  );
};
export const txPendingToast = ({ children, message }: { children: ReactNode; message: string }) => {
  return toast(
    <div className="flex flex-col items-start gap-1">
      <div className="flex w-[200px] min-w-0 flex-row items-center justify-start gap-1 overflow-hidden text-ellipsis text-nowrap">
        {children}
      </div>
      <p className="text-subtitle">{message}</p>
    </div>,
    {
      className: 'custom pending',
    },
  );
};
export const txErrorToast = ({ error, children }: { error: Error; children: ReactNode }) => {
  const message = `
  ${error.name ? `Error: ${error.name}` : ''}
  ${error.name ? `message: ${error.message}` : ''}
  ${error.cause ? `cause: ${error.cause}` : ''}
  ${error.cause ? `stack: ${error.stack}` : ''}
  `;
  return toast(
    <div className="flex flex-col items-start gap-1" onClick={() => copyToClipboard(message)}>
      <div className="flex w-[200px] min-w-0 flex-row items-center justify-start gap-1 overflow-hidden text-ellipsis text-nowrap">
        {children}
      </div>
      <p className="font-bold text-white">Encountered an error:</p>
      <pre className="pb-4. relative w-full rounded-xl bg-white/10 p-2 font-mono text-white backdrop-blur-lg">
        {error.message}
        <FaCopy className="absolute bottom-2 right-2" />
      </pre>
    </div>,
    {
      className: 'custom error',
    },
  );
};
