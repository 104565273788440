import { Suspense, lazy, useEffect, useRef } from 'react';
import { Route, Routes, useLocation } from 'react-router';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
// import './Styles/custom.scss';
import { QueryProvider } from './state/providers/query';
import { WalletConnectProvider } from './state/providers/wallet';
import { MainLayout } from 'ui/layout/layout';
import { ErrorBoundary, Page404 } from 'ui/pages/errors';
import Pending from 'ui/layout/pending';
import { PrivateRoute } from 'ui/components/private-route';
import { useVestingPermission } from 'api/terraport/vesting';
import { Button, NotAButton } from 'ui/components/button';
import { useLocalStorage } from '@uidotdev/usehooks';
import { OpModal } from 'ui/modals/paid-op-modal';
import { IS_DEV } from 'consts/misc';
import { HeaderMetas } from 'ui/layout/metas';
import CompetitionPage from 'ui/pages/competition';

const Farming = lazy(() => import('./ui/pages/farming'));
const Governance = lazy(() => import('./ui/pages/governance'));
const Staking = lazy(() => import('./ui/pages/staking'));
const Homepage = lazy(() => import('./ui/pages/homepage'));
const Development = lazy(() => import('./ui/pages/development'));
const PrivacyPolicy = lazy(() => import('./ui/pages/others/PrivacyPolicy'));
const TermsOfUse = lazy(() => import('./ui/pages/others/TermsOfUse'));
// const Leaderboard = lazy(() => import('./ui/pages/leaderboard'));
const Trade = lazy(() => import('./ui/pages/trade'));
const Swap = lazy(() => import('./ui/pages/swap'));
const LaunchpadHistory = lazy(() => import('./ui/pages/launchpad-history'));
const SeleniumLaunchpad = lazy(() => import('./ui/pages/selenium-launchpad'));
const Vesting = lazy(() => import('./ui/pages/vesting'));
const LiquidStaking = lazy(() => import('./ui/pages/liquidstaking'));

function App() {
  const [allowPrompt, setAllowPrompt] = useLocalStorage('allow-install-prompt', IS_DEV);
  useEffect(() => {
    const handlePrompt = (e) => {
      e.preventDefault();
      if (allowPrompt) {
        toast.info(
          <div className="flex flex-col items-start gap-2">
            <p>Get Terraport on your device!</p>
            <div className="flex flex-row items-start gap-2">
              <Button
                className=""
                onClick={() => {
                  e.prompt().then(({ outcome }) => {
                    if (outcome === 'dismissed') {
                      setAllowPrompt(false);
                    }
                  });
                }}>
                INSTALL NOW
              </Button>
              <NotAButton className="text-white/40" onClick={() => setAllowPrompt(false)}>
                Dismiss
              </NotAButton>
            </div>
          </div>,
          { autoClose: false },
        );
      }
    };
    window.addEventListener('beforeinstallprompt', handlePrompt);
    return () => window.removeEventListener('beforeinstallprompt', handlePrompt);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allowPrompt]);
  const location = useLocation();
  const oldPage = useRef(location.pathname);
  const hasVestingAccess = useVestingPermission().found;

  useEffect(() => {
    if (location.pathname !== oldPage.current) {
      try {
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
      } catch (error) {
        // for older browser
        window.scrollTo(0, 0);
      }
      oldPage.current = location.pathname;
    }
  }, [location.pathname]);

  useEffect(() => {
    console.log(`terraport v${APP_VERSION}`);
  }, []);

  return (
    <>
      <HeaderMetas />
      <Suspense fallback={<Pending />}>
        <QueryProvider>
          <WalletConnectProvider>
            <MainLayout>
              <ErrorBoundary>
                <OpModal />
                <Suspense fallback={<Pending />}>
                  <Routes location={location}>
                    <Route path="/" element={<Homepage />} />
                    <Route path="/trade/:pairAddr" element={<Swap />} />
                    <Route path="/trade" element={<Trade />} />
                    <Route path="/placeholder" element={<Trade />} />
                    <Route path="/farming" element={<Farming />} />
                    <Route path="/governance" element={<Governance />} />
                    <Route path="/staking" element={<Staking />} />
                    <Route path="/development/*" element={<Development />} />
                    <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
                    <Route path="/termsOfUse" element={<TermsOfUse />} />
                    <Route path="/competition" element={<CompetitionPage />} />
                    {/* <Route path="/leaderboard" element={<Leaderboard />} /> */}
                    <Route path="/launchpad" element={<SeleniumLaunchpad />} />
                    <Route path="/history-launchpad" element={<LaunchpadHistory />} />
                    <Route path="/liquidstaking" element={<LiquidStaking />} />
                    <Route
                      path="/vesting"
                      element={<PrivateRoute hidden={() => !hasVestingAccess} element={<Vesting />} />}
                    />
                    <Route path="*" element={<Page404 />} />
                  </Routes>
                </Suspense>
              </ErrorBoundary>
            </MainLayout>
            <ToastContainer
              toastClassName="!rounded-12 !bg-gradient-to-l !from-gr1 !to-gr2 !text-white"
              position="bottom-right"
              // autoClose={false}
              hideProgressBar={true}
              newestOnTop={true}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss={false}
              draggable
              pauseOnHover={true}
              stacked
            />
          </WalletConnectProvider>
        </QueryProvider>
        <svg width="0" height="0" className="-right[-999px] absolute -bottom-[999px]">
          <defs>
            <clipPath width="48.73" height="26.74" id="card-end" clipPathUnits="objectBoundingBox">
              <path
                transform="scale(0.02, 0.0374)"
                className="cls-1"
                d="M48.73,0l-16.77,16.73c-6.4,6.4-15.08,10.01-24.14,10.01H0V0h7.17v.05l41.56-.05Z"
              />
            </clipPath>
            <clipPath
              width="157"
              height="64"
              xmlns="http://www.w3.org/2000/svg"
              id="podiumPath"
              clipPathUnits="objectBoundingBox">
              <path
                transform="scale(0.006405, 0.016)"
                d="M156.05 20.06C156.05 15.58 152.42 11.95 147.94 11.95H106.64C102.33 11.95 98.2599 10.22 95.0699 7.31998C90.5299 3.18998 84.4999 0.669983 77.8799 0.669983C71.2599 0.669983 65.2299 3.18998 60.6899 7.31998C57.4999 10.22 53.4299 11.95 49.1199 11.95H8.15991C3.67991 11.95 0.0499268 15.58 0.0499268 20.06V63.04H156.06V20.06H156.05Z"
              />
            </clipPath>
          </defs>
        </svg>
      </Suspense>
    </>
  );
}

export default App;
