import clsx from 'clsx/lite';
import { FormikProps } from 'formik';
import {
  FC,
  InputHTMLAttributes,
  TextareaHTMLAttributes,
  useMemo,
} from 'react';
import Select from 'react-select';
import { PlainJsonEditor } from 'react-plain-json-editor';
import { NumericFormat } from 'react-number-format';

type Props = {
  name: string;
  label?: string;
  formik: FormikProps<any>;
  inputClassname?: string;
};

type TextProps = Props & InputHTMLAttributes<HTMLInputElement>;

const n = Intl.NumberFormat().formatToParts(10000000.1);
const groupSeparator = n.find((p) => p.type === 'group')?.value ?? ',';
const decSeparator = n.find((p) => p.type === 'decimal')?.value ?? '.';
export const FormikTextInput = ({
  name,
  formik,
  label = '',
  className,
  inputClassname,
  ...inputProps
}: TextProps) => {
  const fieldMeta = useMemo(() => formik.getFieldMeta(name), [name, formik]);
  const fieldProps = useMemo(() => formik.getFieldProps(name), [name, formik]);
  const isInvalid = useMemo(
    () => fieldMeta.touched && fieldMeta.error !== undefined,
    [fieldMeta],
  );

  const formikInputClassnames =
    'w-full bg-background1 rounded-full outline-none border-none px-2 py-1 box-border text-white placeholder:text-white/40';

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    formik.handleChange(e);
    inputProps.onChange?.(e);
  };

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    if (fieldProps.value === fieldMeta.initialValue) {
      formik.setFieldValue(name, '');
    }
    inputProps.onFocus?.(e);
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    formik.handleBlur(e);
    inputProps.onBlur?.(e);
  };

  const handleNumericChange = (values: {
    formattedValue: string;
    value: string;
  }) => {
    const { value } = values;
    formik.setFieldValue(fieldProps.name, value);
    inputProps.onChange?.({ target: { value } });
  };

  return (
    <div
      className={clsx(
        'flex w-[inherit] flex-col items-start gap-1',
        className,
      )}>
      {label !== '' && (
        <div className="flex flex-row gap-2">
          <p className="font-bold">{label}</p>
          <p className="h-6 text-theme-red">{isInvalid && fieldMeta.error}</p>
        </div>
      )}
      {inputProps.type === 'number' ? (
        <NumericFormat
          className={clsx(formikInputClassnames, inputClassname)}
          placeholder={inputProps.placeholder ?? label}
          id={name}
          name={name}
          onFocus={handleFocus}
          onValueChange={handleNumericChange}
          onBlur={handleBlur}
          value={fieldProps.value ?? ''}
          thousandSeparator={groupSeparator}
          decimalSeparator={decSeparator}
          displayType="input"
          allowLeadingZeros={false}
          autoComplete="off"
        />
      ) : (
        <input
          className={clsx(formikInputClassnames, inputClassname)}
          {...inputProps}
          placeholder={inputProps.placeholder ?? label}
          id={name}
          name={name}
          onFocus={handleFocus}
          onChange={handleChange}
          onBlur={handleBlur}
          value={fieldProps.value ?? ''}
        />
      )}
    </div>
  );
};

type TextAreaProps = Props & TextareaHTMLAttributes<HTMLTextAreaElement>;

export const FormikTextAreaInput = ({
  name,
  formik,
  label,
  className,
  ...inputProps
}: TextAreaProps) => {
  const fieldMeta = useMemo(() => formik.getFieldMeta(name), [name, formik]);
  const fieldProps = useMemo(() => formik.getFieldProps(name), [name, formik]);
  const isInvalid = useMemo(
    () => fieldMeta.touched && fieldMeta.error !== undefined,
    [fieldMeta],
  );

  return (
    <div className={clsx('flex flex-col items-start gap-1', className)}>
      <div className="flex flex-row gap-2">
        <p className="font-bold">{label}</p>
        <p className="h-6 text-theme-red">{isInvalid && fieldMeta.error}</p>
      </div>
      <textarea
        className={
          'box-border w-full resize-none rounded-xl border-none bg-background1 px-2 py-1 text-white placeholder:text-white/40'
        }
        placeholder="Enter text"
        {...inputProps}
        id={name}
        name={name}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={fieldProps.value ?? ''}
      />
    </div>
  );
};

type SelectProps = Props & {
  isDisabled?: boolean;
};

export type StringOption = { value: any; label: string };

type StringSelectProps = SelectProps & {
  options: StringOption[];
  fallbackOption?: StringOption;
  isSearcheable: boolean;
  className?: string;
  onFocus?: () => void;
};

export const FormikStringSelect = ({
  options,
  name,
  formik,
  fallbackOption = { value: '', label: '' },
  label,
  isDisabled,
  isSearcheable,
  className,
  ...rest
}: StringSelectProps) => {
  const fieldProps = useMemo(() => formik.getFieldProps(name), [name, formik]);
  const fieldMeta = useMemo(() => formik.getFieldMeta(name), [name, formik]);
  const isInvalid = useMemo(
    () => fieldMeta.touched && fieldMeta.error !== undefined,
    [fieldMeta],
  );
  return (
    <div className={clsx('flex flex-col items-start gap-1', className)}>
      <div className="flex flex-row gap-2">
        <p className="font-bold">{label}</p>
        <p className="h-6 text-theme-red">{isInvalid && fieldMeta.error}</p>
      </div>
      <Select
        // placeholder=""
        classNames={{
          singleValue: () => '!text-white',
          control: () =>
            '!bg-transparent !border-none !focus:bg-background1 !focus:border-none !text-white !h-6 !min-h-0',
          option: () => '!bg-background1 !hover:bg-background2',
          menu: () => '!bg-background1',
          indicatorSeparator: () => 'hidden',
          indicatorsContainer: () => '!p-0',
          dropdownIndicator: () => '!py-0',
        }}
        className={
          'box-border h-6 w-full rounded-full border-none bg-background1 outline-none'
        }
        id={name}
        name={name}
        onChange={(option) => formik.setFieldValue(name, option?.value)}
        onBlur={formik.handleBlur}
        options={options}
        value={
          options.find((option) => option.value === fieldProps.value) ??
          fallbackOption
        }
        filterOption={(option, inputValue) =>
          option.label.toLowerCase().includes(inputValue.toLowerCase())
        }
        isDisabled={isDisabled}
        isSearchable={isSearcheable}
        {...rest}
      />
    </div>
  );
};

export const FormikJson: FC<Props & { onFocus: () => void }> = ({
  name,
  label,
  formik,
  onFocus,
}) => {
  const fieldMeta = useMemo(() => formik.getFieldMeta(name), [name, formik]);
  const fieldProps = useMemo(() => formik.getFieldProps(name), [name, formik]);
  const isInvalid = useMemo(() => fieldMeta.error !== undefined, [fieldMeta]);

  return (
    <div className="flex flex-col items-start gap-1" onFocus={() => onFocus()}>
      <div className="flex flex-row gap-2">
        <p className="font-bold">{label}</p>
        <p className="h-6 text-theme-red">{isInvalid && fieldMeta.error}</p>
      </div>
      <PlainJsonEditor
        value={fieldProps.value}
        onChange={(value) => {
          formik.setFieldValue(name, value);
        }}
        styles={{
          root: {
            width: '100%',
            height: '140px',
          },
          textarea: {
            resize: 'none',
            border: 'none',
            borderRadius: '1rem',
            backgroundColor: 'rgb(32 56 91)',
            color: '#eFF',
            padding: '0.5rem',
            fontFamily: 'monospace',
            fontSize: '12px',
          },
        }}
      />
    </div>
  );
};

//  initialValue={fieldProps.value}
//  onChange={(value) => {
//    formik.setFieldValue(name, value);
//  }}
