import { Dialog, Input, DialogBackdrop, DialogPanel, Button } from '@headlessui/react';
import { useInfiniteQuery } from '@tanstack/react-query';
import { baseUrl } from 'api/terraport/base';
import axios from 'axios';
import clsx from 'clsx/lite';
import { map } from 'lodash';
import { FC, useState } from 'react';
import { FaSearch } from 'react-icons/fa';
import { pairState } from 'state/trade';
import { useAddress } from 'state/wallet-state';
import { PairInfo } from 'types/pairs';
import { PaginationObserver } from './data-grid';
import { useNavigate } from 'react-router';
import { FaX } from 'react-icons/fa6';
import { FormattedNumber } from './formatted-number';

const PG_LIMIT = 100;

const ToggleSwitch: FC<{
  value: boolean;
  onChange: (value: boolean) => void;
  label: string;
}> = ({ value, onChange, label }) => (
  <div className="flex flex-row items-center gap-1">
    <div
      className={clsx(
        value ? 'bg-accent-btn' : 'bg-gr2',
        'relative h-4 w-6 cursor-pointer rounded-full p-0.5',
      )}
      onClick={() => onChange(!value)}>
      <div className={clsx(value ? 'ml-2' : 'ml-0', 'size-3 rounded-full bg-background3 transition-all')} />
    </div>
    <p className="text-xs text-white">{label}</p>
  </div>
);

export const PairOption: FC<{
  pair: PairInfo;
  onSelect: () => void;
  index?: number;
}> = ({ pair, onSelect, index }) => {
  const address = useAddress();
  return (
    <div
      key={pair.contract_addr}
      className={clsx(
        'transition hover:bg-gradient-to-r hover:from-theme-yellow1/80',
        'bg-background2',
        'relative mb-px box-border flex w-full select-none flex-col items-center gap-2 overflow-x-hidden p-2 text-sm text-white',
        'animate-flip-down',
      )}
      style={{
        animationDelay: `${20 * (index ?? 0)}ms`,
      }}
      onClick={() => onSelect()}>
      <div className="flex w-full flex-row items-start">
        <div className="flex items-center">
          <img src={pair.first_token.img} className="z-[1] ml-1 !h-8 !w-8 rounded-full bg-background4 p-1" />
          <img
            src={pair.second_token.img}
            className="relative -left-3 ml-1 !h-8 !w-8 rounded-full bg-background4 p-1"
          />
        </div>
        <div className="flex w-full flex-col gap-1 !text-[10px]">
          <div className="flex w-full flex-row items-center gap-1">
            <p
              className={clsx(
                'min-w-0 text-ellipsis whitespace-nowrap text-nowrap font-logo font-bold',
                !pair.verified && 'text-white/60',
              )}>
              {pair.first_token.denom ?? pair.first_token.name}
            </p>
            <img src="/assets/svg/reverse-btn.svg" className="size-4" />
            <p
              className={clsx(
                'min-w-0 text-ellipsis whitespace-nowrap text-nowrap font-logo font-bold',
                !pair.verified && 'text-white/60',
              )}>
              {pair.second_token.denom ?? pair.second_token.name}
            </p>
            {pair.dangerous && (
              <p className="center h-4 rounded-full bg-theme-red/60 px-2 text-[10px] font-bold text-text-accent">
                Danger
              </p>
            )}
            {pair.verified && (
              <p className="center h-4 rounded-full bg-theme-yellow1/80 px-2 text-[10px] font-bold text-text-accent">
                Verified
              </p>
            )}
            {pair.admin_address === address && (
              <p className="center h-4 rounded-full bg-gr2 px-2 text-[10px] font-bold">Created</p>
            )}
          </div>
          <div className="flex flex-row items-start gap-1">
            <p
              className={clsx(
                'center h-4 w-fit rounded-full bg-gradient-to-l from-theme-purple to-theme-purple2 px-2 text-xs font-bold',
              )}>
              {pair.type === 'concentrated' ? 'CLP' : pair.type}
            </p>
            <p
              className={clsx(
                'center h-4 w-fit rounded-full bg-gradient-to-r via-30% px-2 text-xs font-bold',
                pair.first_token.type === 'native'
                  ? 'from-theme-purple2 via-theme-purple2'
                  : 'from-gr1 via-gr1',
                pair.second_token.type === 'native' ? 'to-theme-purple' : 'to-gr2',
              )}>
              {pair.first_token.type}/{pair.second_token.type}
            </p>
          </div>
          <FormattedNumber
            className="text-xs"
            prefix={<span className="mr-1 text-white/80">Liquidity: </span>}
            value={pair.liquidity_usd}
            suffix="$"
          />
          <span className="-mt-1 flex text-xs">
            <FormattedNumber
              className="mr-1"
              prefix={<span className="mr-1 text-white/80">24h Volume: </span>}
              value={Number(pair.volume_24h)}
              suffix="$"
            />
            {Number(pair.volume_24h) && pair.volume_previous_24h ? (
              <>
                (
                <FormattedNumber
                  className={clsx(
                    Number(pair.volume_24h) < pair.volume_previous_24h
                      ? 'text-theme-red'
                      : 'text-theme-green',
                    'text-xs',
                  )}
                  prefix={Math.sign(Number(pair.volume_24h) - pair.volume_previous_24h) >= 0 ? '+' : '-'}
                  value={Math.abs(Number(pair.volume_24h) - pair.volume_previous_24h)}
                  suffix="$"
                />
                )
              </>
            ) : null}
          </span>
        </div>
      </div>
    </div>
  );
};

export const SearchBar: FC = () => {
  const degen = pairState.use.degen();
  const [search, setSearch] = useState('');
  const address = useAddress();

  const { data, fetchNextPage, hasNextPage } = useInfiniteQuery({
    queryFn: async ({ pageParam }) => {
      const resp = await axios.get<PairInfo[]>(`${baseUrl}/degen/pairs`, {
        params: {
          q: search,
          // creator_address: address,
          whitelisted: !degen,
          offset: pageParam * PG_LIMIT,
          limit: PG_LIMIT,
        },
      });
      return resp.data;
    },
    queryKey: ['pairs', search, degen, address],
    initialPageParam: 0,
    getNextPageParam: (lastPage, _, lastPageParam) => {
      if (lastPage.length === 0) {
        return undefined;
      }
      return lastPageParam + 1;
    },
  });

  const navigate = useNavigate();

  const [isOpen, setOpen] = useState(false);

  const onClose = () => {
    setSearch('');
    setOpen(false);
  };

  const handleSelection = (pair: PairInfo) => {
    setSearch('');
    navigate({ pathname: `/trade/${pair.contract_addr}` });
    onClose();
  };

  return (
    <>
      <Button
        className="flex h-7 w-7 animate-fade items-center justify-between gap-2 self-center justify-self-end rounded-full bg-white/10 px-2 py-1 text-white/40 transition-all sm:w-auto md:min-w-40 md:grow"
        onClick={() => setOpen(true)}>
        <span className="hidden sm:flex">Search</span>
        <FaSearch className="text-white" />
      </Button>
      <Dialog open={isOpen} onClose={onClose} className="relative z-10">
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-black/30 backdrop-blur-md duration-300 ease-out data-[closed]:opacity-0"
        />
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-dvh items-center justify-center text-center">
            <DialogPanel
              transition
              className="flex h-[90dvh] w-[90dvw] transform flex-col overflow-hidden rounded-3xl bg-background3 shadow-xl transition-all data-[closed]:scale-95 data-[closed]:opacity-0 sm:h-[600px] sm:w-[400px]">
              <div className="flex flex-col items-start gap-4 p-4">
                <FaX className="self-end text-white" onClick={onClose} />
                <div className="flex w-full cursor-pointer items-center justify-start gap-2 rounded-full bg-gr2 p-2">
                  <FaSearch className="text-white" />
                  <Input
                    className="grow border-none bg-transparent text-white focus:outline-none"
                    autoFocus
                    value={search}
                    onChange={(e) => setSearch(e.target.value.toLowerCase())}
                  />
                  <FaX className="text-white/60" onClick={() => setSearch('')} />
                </div>
                <div className="flex w-full flex-row items-center justify-between gap-2">
                  <ToggleSwitch value={degen} onChange={pairState.set.degen} label="Degen Mode" />
                  <div className="center gap-2 rounded-xl bg-background2 px-2 py-1 text-sm font-bold text-white/60">
                    Chain 1<p className="rounded-lg bg-background4 px-2 py-1 font-bold">Terra Classic</p>
                  </div>
                </div>
              </div>
              <div className="custom-scrollbar h-full overflow-y-auto">
                {map(data?.pages, (page) =>
                  map(page, (option, i) => (
                    <PairOption
                      key={option.contract_addr}
                      pair={option}
                      onSelect={() => handleSelection(option)}
                      index={i}
                    />
                  )),
                )}
                <PaginationObserver hasNext={hasNextPage} addPage={fetchNextPage} />
              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>
    </>
  );
};
