import {
  Dialog,
  DialogPanel,
  Transition,
  TransitionChild,
} from '@headlessui/react';
import { TERRA_TOKEN_ADDRESS } from 'consts/misc';
import { useCW20Balance } from 'api/rpc/balance';
import { postOrderError } from 'api/terraport/payments';
import clsx from 'clsx/lite';
import { isEmpty, noop } from 'lodash';
import { FC, Fragment, useEffect, useState } from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import { FaCircleXmark, FaEllipsisVertical } from 'react-icons/fa6';
import { OP_PROGRESS, OP_TYPE, opState } from 'state/paid-ops';
import { Button, NotAButton } from 'ui/components/button';
import { Card } from 'ui/components/card';
import { FormattedNumber } from 'ui/components/formatted-number';
import { convertMicroDenomToDenom } from 'helper/utils';

const titles = {
  [OP_TYPE.TEST]: 'Test Operation',
  [OP_TYPE.DEV_FARM]: 'New Farm',
  [OP_TYPE.DEV_TOKEN]: 'New Token',
  [OP_TYPE.DEV_PAIR_CW20_CW20]: 'New Pair',
  [OP_TYPE.DEV_PAIR_CW20_NATIVE]: 'New Pair',
};

const success = {
  [OP_TYPE.TEST]: 'Test Operation Completed',
  [OP_TYPE.DEV_FARM]:
    'Yor new farm will be available shortly in the development section',
  [OP_TYPE.DEV_TOKEN]:
    'Yor new token will be available shortly in the development section',
  [OP_TYPE.DEV_PAIR_CW20_CW20]:
    'Yor new pair will be available shortly in the development section',
  [OP_TYPE.DEV_PAIR_CW20_NATIVE]:
    'Yor new pair will be available shortly in the development section',
};

const steps = {
  [OP_PROGRESS.ORDER_POSTED]: {
    done: 'Order created',
    label: 'Order creation',
    fail: 'Order failed:',
  },
  [OP_PROGRESS.COMPLETED]: {
    done: 'Operation completed',
    label: 'Operation',
    fail: 'Operation failed:',
  },
  [OP_PROGRESS.PAYMENT_SIGNED]: {
    done: 'Payment signed',
    label: 'Payment signature',
    fail: 'Payment failed:',
  },
  [OP_PROGRESS.PAYMENT_CONFIRMED]: {
    done: 'Payment confirmed',
    label: 'Payment confirmation',
    fail: 'Payment confirmation failed:',
  },
};

const ProgressCard: FC<{
  done: boolean;
  pending: boolean;
  step: { done: string; fail: string; label: string };
  error: string;
}> = ({ done, pending, step, error }) => (
  <div
    className={clsx(
      pending ? 'animate-pulse' : 'animate-none',
      done ? 'bg-background2' : error ? 'bg-theme-red/40' : 'bg-white/20',
      'flex h-fit flex-row items-center gap-2 rounded-xl p-2',
    )}>
    {done ? (
      <FaCheckCircle className="size-8 min-w-8 self-start text-theme-green" />
    ) : error ? (
      <FaCircleXmark className="size-8 min-w-8 self-start text-theme-red" />
    ) : (
      <FaEllipsisVertical className="size-8 min-w-8 animate-pulse self-start text-white/40" />
    )}
    <div className="flex flex-col items-start justify-center gap-1">
      <p>{done ? step.done : error ? step.fail : step.label}</p>
      {error ? (
        <p className="text-start text-theme-red">{error.toString()}</p>
      ) : null}
    </div>
  </div>
);

export const OpModal: FC = () => {
  const currentOp = opState.use.currentOp();
  const opId = currentOp?.info.order.hash;
  const balance = useCW20Balance(currentOp?.info.order.price_asset ?? null);
  const priceDenom = convertMicroDenomToDenom(currentOp?.info.order.price ?? 0);

  const [errors, setError] = useState<Record<OP_PROGRESS, string>>({});

  useEffect(() => {
    if (!currentOp) {
      setError({});
    }
  }, [currentOp]);

  const [signingPayment, setSigningPayment] = useState(false);
  const [confirmingPayment, setConfirmingPayment] = useState(false);
  const [executingOp, setExecutingOp] = useState(false);

  const handleError = (error: string | unknown) => {
    if (opId) {
      postOrderError(opId, currentOp, error);
      setError((e) => ({ ...e, [currentOp.state + 1]: error }));
    }
  };

  const payOp = () => {
    if (opId) {
      setSigningPayment(true);
      try {
        opState.set
          .payOperation(opId)
          .catch(handleError)
          .then(() => setError({}))
          .finally(() => setSigningPayment(false));
      } catch (error) {
        handleError(error);
      }
    }
  };

  const confirmPayment = () => {
    if (opId) {
      setConfirmingPayment(true);
      try {
        opState.set
          .confirmPayment(opId)
          .catch(handleError)
          .then(() => setError({}))
          .finally(() => setConfirmingPayment(false));
      } catch (error) {
        handleError(error);
      }
    }
  };

  const executeOp = () => {
    if (opId) {
      setExecutingOp(true);
      try {
        opState.set
          .executeOperation(opId)
          .catch(handleError)
          .then(() => setError({}))
          .finally(() => setExecutingOp(false));
      } catch (error) {
        handleError(error);
      }
    }
  };

  useEffect(() => {
    if (currentOp) {
      if (
        currentOp.state === OP_PROGRESS.PAYMENT_SIGNED &&
        !errors[OP_PROGRESS.PAYMENT_SIGNED]
      ) {
        confirmPayment();
      }
      if (
        currentOp.state === OP_PROGRESS.PAYMENT_CONFIRMED &&
        !errors[OP_PROGRESS.PAYMENT_CONFIRMED]
      ) {
        executeOp();
      }
    }
  }, [currentOp]);

  return (
    <>
      <Transition appear show={!!currentOp} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={noop}>
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <div className="fixed inset-0 bg-background4/50 backdrop-blur-md" />
          </TransitionChild>
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-dvh items-center justify-center text-center">
              <TransitionChild
                as={Fragment}
                enter="ease-out duration-300 delay-150"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95">
                <DialogPanel className={'transform shadow-xl transition-all'}>
                  {currentOp && (
                    <Card
                      title={titles[currentOp.type]}
                      className="max-h-[90dvh] w-[90dvw] sm:w-[500px]"
                      contentClassName="flex flex-col items-stretch justify-start gap-4 p-4 overflow-y-auto">
                      <div
                        className={clsx(
                          'bg-[url("/assets/img/launchpad/top-negative.webp")]',
                          'col-span-full box-border flex min-h-fit w-full flex-col items-start gap-4 rounded-2xl bg-cover bg-right px-8 py-4 text-white',
                        )}>
                        <h3 className="text-start font-bold text-white">
                          You will sign multiple messages
                        </h3>
                        <p className="text-start text-white">
                          Please do not close or navigate away until the process
                          is completed
                        </p>
                      </div>
                      <ProgressCard
                        step={steps[OP_PROGRESS.ORDER_POSTED]}
                        done={currentOp.state >= OP_PROGRESS.ORDER_POSTED}
                        pending={false}
                        error={errors[OP_PROGRESS.ORDER_POSTED]}
                      />
                      <p className="flex flex-row items-center gap-1">
                        Transaction cost:
                        <FormattedNumber
                          className="text-subtitle"
                          value={priceDenom}
                          suffix={
                            currentOp.info.order.price_asset ===
                            TERRA_TOKEN_ADDRESS
                              ? 'TERRA'
                              : ''
                          }
                        />
                        (
                        <FormattedNumber
                          value={
                            Number(currentOp.info.order.price_cents_usd) / 100
                          }
                          suffix="$"
                        />
                        )
                      </p>
                      <p className="flex flex-row items-center gap-1">
                        Available balance:
                        <FormattedNumber
                          className="text-subtitle"
                          value={balance}
                          suffix={
                            currentOp.info.order.price_asset ===
                            TERRA_TOKEN_ADDRESS
                              ? 'TERRA'
                              : ''
                          }
                        />
                      </p>
                      {currentOp.state === OP_PROGRESS.ORDER_POSTED ? (
                        balance < priceDenom ? (
                          <NotAButton className="text-theme-red">
                            Insufficient balance
                          </NotAButton>
                        ) : (
                          <Button
                            disabled={
                              currentOp.state >= OP_PROGRESS.PAYMENT_SIGNED ||
                              balance < priceDenom
                            }
                            onClick={() => payOp()}>
                            Pay Now
                          </Button>
                        )
                      ) : null}
                      <ProgressCard
                        step={steps[OP_PROGRESS.PAYMENT_SIGNED]}
                        done={currentOp.state >= OP_PROGRESS.PAYMENT_SIGNED}
                        pending={signingPayment}
                        error={errors[OP_PROGRESS.PAYMENT_SIGNED]}
                      />
                      {errors[OP_PROGRESS.PAYMENT_SIGNED] && (
                        <Button onClick={() => payOp()}>Retry</Button>
                      )}
                      <ProgressCard
                        step={steps[OP_PROGRESS.PAYMENT_CONFIRMED]}
                        done={currentOp.state >= OP_PROGRESS.PAYMENT_CONFIRMED}
                        pending={confirmingPayment}
                        error={errors[OP_PROGRESS.PAYMENT_CONFIRMED]}
                      />
                      {errors[OP_PROGRESS.PAYMENT_CONFIRMED] && (
                        <Button onClick={() => confirmPayment()}>Retry</Button>
                      )}
                      <ProgressCard
                        step={steps[OP_PROGRESS.COMPLETED]}
                        done={currentOp.state >= OP_PROGRESS.COMPLETED}
                        pending={executingOp}
                        error={errors[OP_PROGRESS.COMPLETED]}
                      />
                      {errors[OP_PROGRESS.COMPLETED] && (
                        <Button onClick={() => executeOp()}>Retry</Button>
                      )}
                      {!isEmpty(errors) && (
                        <div
                          className={clsx(
                            'bg-[url("/assets/img/launchpad/top-negative.webp")]',
                            'col-span-full mt-auto box-border flex min-h-24 w-full flex-col items-stretch gap-4 rounded-2xl bg-cover bg-right px-8 py-4 text-white',
                          )}>
                          <h3 className="text-start font-bold text-white">
                            There was an error
                          </h3>
                          <p className="text-start text-white">
                            Please contact the team on telegram official channel
                          </p>
                        </div>
                      )}
                      {currentOp.state === OP_PROGRESS.ORDER_POSTED &&
                        isEmpty(errors) && (
                          <Button
                            className="mt-auto"
                            onClick={() => opState.set.current(null)}>
                            Cancel
                          </Button>
                        )}
                      {currentOp.state > OP_PROGRESS.ORDER_POSTED &&
                        currentOp.state < OP_PROGRESS.COMPLETED &&
                        isEmpty(errors) && (
                          <NotAButton className="mt-auto">Close</NotAButton>
                        )}
                      {currentOp.state === OP_PROGRESS.COMPLETED && (
                        <div
                          className={clsx(
                            'bg-[url("/assets/img/launchpad/top-positive.webp")]',
                            'col-span-full mt-auto box-border flex min-h-fit w-full flex-col items-stretch gap-4 rounded-2xl bg-cover bg-right px-8 py-4 text-white',
                          )}>
                          <h3 className="text-start font-bold text-white">
                            Success
                          </h3>
                          <p className="text-start text-white">
                            {success[currentOp.type]}
                          </p>
                        </div>
                      )}
                      {(currentOp.state === OP_PROGRESS.COMPLETED ||
                        !isEmpty(errors)) && (
                        <Button
                          className="mt-auto"
                          onClick={() => opState.set.current(null)}>
                          Close
                        </Button>
                      )}
                    </Card>
                  )}
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};
