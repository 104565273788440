import { ROCKET_TERRA_PAIR_ADDR } from 'consts/common-tokens';
import { PairInfo, TradePair } from 'types/pairs';
import { createStore } from 'zustand-x';

export type PairState = {
  pair: PairInfo | null;
  tradePair: TradePair | null;
  reversed: boolean;
  degen: boolean;
};

export const pairState = createStore('pairs')<PairState>({
  degen: false,
  reversed: false,
  pair: null,
  tradePair: null,
})
  .extendActions((set, get) => ({
    toggleReverse: () => {
      set.reversed(!get.reversed());
    },
    pair: (pair: PairInfo) => {
      const isBidirectional = pair.type === 'v2';
      const isV2cw20Native = pair.asset_infos[0].type === 'cw20' && pair.asset_infos[1].type === 'native';
      set.reversed((!isBidirectional && isV2cw20Native) || pair.contract_addr === ROCKET_TERRA_PAIR_ADDR);
      set.pair(pair);
    },
  }))
  .extendSelectors((state) => ({
    fromAsset: () => state.pair?.[state.reversed ? 'second_token' : 'first_token'] ?? null,
    toAsset: () => state.pair?.[state.reversed ? 'first_token' : 'second_token'] ?? null,
  }));
