import { FC } from 'react';
import { IconBaseProps } from 'react-icons/lib';

export const ShipIcon: FC<IconBaseProps> = (props) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 558.1 530">
    <path d="M467.5,334.4c-8.5-6.6-14.1-16.1-15.6-26.8C435.9,194.6,364.8,0,279.6,0S123.6,193.6,107.4,306.7c-1.5,10.7-7.2,20.3-15.8,26.9C40.3,372.5,6.8,431.2,0,496.3l132.2-38c13.5-3.9,28-3.4,40.7,2.6,17.5,8.2,37.7,13.6,59.5,16.6,15.5,2.2,28.5,13,33.9,27.7l9.1,24.8h7.3l9-24.6c5.4-14.8,18.4-25.6,34-27.7,21.6-2.9,41.5-8.1,58.9-16,13.1-5.9,27.8-7.1,41.7-3.1l131.8,37.8c-6.7-64.7-39.9-123-90.6-161.9h0ZM279.6,423.5c-58,0-105-47-105-105s47-105,105-105,105,47,105,105-47,105-105,105Z" />
  </svg>
);

export const GovernanceIcon: FC<IconBaseProps> = (props) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 55.1 58.66">
    <path d="M49.08,0H6.01C2.69,0,0,2.69,0,6.01v48.54c0,2.94,3.01,4.93,5.72,3.78l20.22-8.63c1.03-.44,2.19-.44,3.22,0l20.22,8.63c2.71,1.16,5.72-.83,5.72-3.78V6.01c0-3.32-2.69-6.01-6.01-6.01ZM47.65,17.66l-20.72,20.72c-.35.35-.68.59-1.03.75-.48.22-.99.33-1.49.33-.89,0-1.76-.35-2.42-1.01l-12.96-12.96c-1.34-1.34-1.34-3.52,0-4.86,1.34-1.34,3.52-1.34,4.86,0l8.81,8.81c.92.92,2.53.92,3.45,0l16.64-16.64c1.3-1.3,3.56-1.3,4.86,0,.65.65,1.01,1.51,1.01,2.43s-.36,1.78-1.01,2.43Z" />
  </svg>
);

export const TradeIcon: FC<IconBaseProps> = (props) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 67.15 73.01">
    <rect y="24.98" width="16.64" height="48.04" rx="7.97" ry="7.97" />
    <rect
      x="50.51"
      y="45.17"
      width="16.64"
      height="27.84"
      rx="7.97"
      ry="7.97"
    />
    <rect x="25.26" width="16.64" height="73.01" rx="7.97" ry="7.97" />
  </svg>
);

export const MoreListIcon: FC<IconBaseProps> = (props) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 73.01 66.59">
    <rect x="14" y="0" width="45.01" height="14.64" rx="7.32" ry="7.32" />
    <rect
      x="29.19"
      y="-2.58"
      width="14.64"
      height="73.01"
      rx="7.32"
      ry="7.32"
      transform="translate(2.58 70.43) rotate(-90)"
    />
    <rect
      x="29.19"
      y="32.77"
      width="14.64"
      height="53.01"
      rx="7.32"
      ry="7.32"
      transform="translate(-22.77 95.78) rotate(-90)"
    />
  </svg>
);

export const SwapIcon: FC<IconBaseProps> = (props) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 73.24 73.24">
    <path d="M40.85,23.47c0,2.33,1.9,4.23,4.23,4.23h.49c2.33,0,4.23-1.9,4.23-4.23V4.48c0-1.91-1.17-3.56-2.97-4.22-.47-.17-1-.26-1.62-.26h-18.88c-2.33,0-4.23,1.9-4.23,4.23v.49c0,2.33,1.9,4.23,4.23,4.23h8.18L1.24,42.24c-.8.8-1.24,1.86-1.24,2.99s.44,2.19,1.24,2.99l.35.35c.8.8,1.86,1.24,2.99,1.24s2.19-.44,2.99-1.24L40.85,15.29v8.18Z" />
    <path d="M73.24,28.01c0-1.13-.44-2.19-1.24-2.99l-.35-.35c-.8-.8-1.86-1.24-2.99-1.24s-2.19.44-2.99,1.24l-33.28,33.28v-8.18c0-2.33-1.9-4.23-4.23-4.23h-.49c-2.33,0-4.23,1.9-4.23,4.23v19c0,1.9,1.17,3.56,2.97,4.22.47.17,1,.26,1.62.26h18.88c2.33,0,4.23-1.9,4.23-4.23v-.49c0-2.33-1.9-4.23-4.23-4.23h-8.18l33.28-33.28c.8-.8,1.24-1.86,1.24-2.99Z" />
  </svg>
);
