import { useQuery } from '@tanstack/react-query';
import { baseUrl } from 'api/terraport/base';
import axios from 'axios';
import { useAddress, walletState } from 'state/wallet-state';

export const claimQuest = (quest: AggregatedQuest, type: string) =>
  axios.post(
    `${baseUrl}/quests/event`,
    {
      type,
      quest_name: quest.name,
      payload: {},
    },
    {
      headers: {
        Authorization: `Bearer ${walletState.get.jwt()}`,
      },
    },
  );

export type Quest = {
  data: {
    button_enabled: boolean;
    step_reward: number;
    completed_reward: number;
    last_sign_event?: string;
    shared_at?: string;
  };
  name: string;
  state: 'active' | string;
  progress_percentage: number;
  title: string;
  description: string;
};

export type QuestLog = {
  message: string;
  quest_name: string;
  reward: number;
  timestamp: string;
};

export type QuestData = {
  leaderboard_score: number;
  quest_rewards: string;
  quest_rewards_logs: QuestLog[];
  quests: Quest[];
};

export type AggregatedQuest = Quest & {
  logs: QuestLog[];
};

export const useQuests = (address?: string) =>
  useQuery({
    queryKey: ['quests', address],
    enabled: !!address,
    queryFn: async () => {
      const { data } = await axios.get<QuestData>(`${baseUrl}/quests`, {
        headers: {
          Authorization: `Bearer ${walletState.get.jwt()}`,
        },
      });
      return data;
    },
    refetchInterval: 30000,
  });

export const useAvgTerra = () => {
  const address = useAddress();
  return useQuery<{ avgTerra: number }>({
    queryKey: ['avg-terra', address],
    enabled: !!address,
    queryFn: async () => {
      const { data } = await axios.get<{ avgTerra: number }>(`${baseUrl}/me/avg-terra`, {
        headers: {
          Authorization: `Bearer ${walletState.get.jwt()}`,
        },
      });
      return data;
    },
    refetchInterval: 30000,
  });
};

export const postReferral = (address: string, referrer: string) => {
  if (referrer && address) {
    axios.post(
      `${baseUrl}/quests/event`,
      {
        type: 'login',
        quest_name: 'referral',
        payload: {
          wallet_address: referrer,
        },
      },
      {
        headers: {
          Authorization: `Bearer ${walletState.get.jwt()}`,
        },
      },
    );
  }
};
