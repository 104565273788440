import { FC, Fragment, useState } from 'react';
import { Dialog, DialogPanel, Transition } from '@headlessui/react';
import { Button, IconButton } from 'ui/components/button';
import { openWalletConnectModal, walletState } from 'state/wallet-state';
import { FaCheckCircle, FaPlus } from 'react-icons/fa';
import { GiTwoCoins } from 'react-icons/gi';
import clsx from 'clsx/lite';
import { Tooltip } from 'ui/components/tooltip';
import { quickSwap } from './swapper';
import { FaArrowsRotate } from 'react-icons/fa6';

export const ConnectWalletButton = ({ label = 'WALLET' }: { label?: string }) => (
  <Button
    className="center gap-2 bg-gradient-to-t from-gr1 via-gr2 to-gr2 !text-white"
    onClick={openWalletConnectModal}>
    {walletState.use.address() && <FaCheckCircle className="text-theme-green" />}
    {label}
  </Button>
);

export const BuyCrypto: FC<{
  className?: string;
  label?: string;
  asIcon?: boolean;
}> = ({ className, label = 'BUY CRYPTO', asIcon }) => {
  const [buy, setBuy] = useState(false);
  return (
    <>
      {asIcon ? (
        <>
          <IconButton id="btn-buy-crypto" className="relative size-8 p-1" onClick={() => setBuy(true)}>
            <GiTwoCoins className="min-h-6 min-w-6" />
            <FaPlus className="absolute right-1 top-1 !size-2" />
          </IconButton>
          <Tooltip place="left" anchorSelect="#btn-buy-crypto">
            Buy Crypto
          </Tooltip>
        </>
      ) : (
        <Button id="btn-buy-crypto" onClick={() => setBuy(true)} className={clsx('text-nowrap', className)}>
          {label}
        </Button>
      )}
      <Transition
        appear
        show={buy}
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0">
        <Dialog as="div" className="relative z-10" onClose={() => setBuy(false)}>
          <div className="fixed inset-0 bg-black/50 backdrop-blur-md" />
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-dvh items-center justify-center text-center">
              <DialogPanel className="transition-all">
                <iframe
                  className="min-h-[334px] max-w-[450px] rounded-[2rem] border-none"
                  width="450"
                  title="Ramp Widget"
                  src="https://guardarian.com/calculator/v1?partner_api_token=5aec0db8-5cef-4d90-a2c0-023d6a316c14&theme=blue&type=narrow&default_fiat_amount=50&crypto_currencies_list=%5B%7B%22ticker%22%3A%22LUNC%22%2C%22network%22%3A%22LUNC%22%7D%5D&fiat_currencies_list=%5B%7B%22ticker%22%3A%22EUR%22%2C%22network%22%3A%22EUR%22%7D%2C%7B%22ticker%22%3A%22USD%22%2C%22network%22%3A%22USD%22%7D%2C%7B%22ticker%22%3A%22GBP%22%2C%22network%22%3A%22GBP%22%7D%2C%7B%22ticker%22%3A%22TRY%22%2C%22network%22%3A%22TRY%22%7D%5D"
                />
              </DialogPanel>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export const HeaderButtons: FC = () => {
  return (
    <div className="ml-auto flex animate-fade gap-2 self-end animate-delay-200 md:gap-4">
      <IconButton id="btn-quickswap" className="size-8 p-1" onClick={() => quickSwap.set.open(true)}>
        <FaArrowsRotate className="min-h-5 min-w-5" />
      </IconButton>
      <Tooltip anchorSelect="#btn-quickswap" place="left">
        Quick Swap
      </Tooltip>
      <BuyCrypto className="hidden lg:block" asIcon />
      <ConnectWalletButton />
    </div>
  );
};
