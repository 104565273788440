import { FC, Fragment, useEffect, useState } from 'react';
import { CardModal } from '../components/modal';
import { Button, IconButton } from '../components/button';
import {
  closeWalletConnectModal,
  connectWallet,
  controllers,
  walletState,
} from '../../state/wallet-state';
import { WalletController, WalletType } from 'cosmes/wallet';
import { DEFAULT_CHAIN } from '../../consts/misc';
import { FaX } from 'react-icons/fa6';
import { capitalize } from 'lodash';
import { Planet, Star } from 'ui/components/planet';
import { BsCopy } from 'react-icons/bs';
import { copyToClipboard } from 'helper/utils';
import { ConnectedWalletModal } from './connected-wallet-modal';

const CurrentWallet = () => {
  const wallet = walletState.use.wallet();
  const controller = walletState.use.currentController();
  return wallet ? (
    <div className="flex w-full flex-col items-start gap-4">
      <div className="flex w-full min-w-0 flex-row items-center justify-between">
        <span className="min-w-0 overflow-hidden text-ellipsis text-nowrap text-xl">
          {wallet.address.slice(0, -4)}
        </span>
        <span className="mr-auto text-xl">{wallet.address.slice(-4)}</span>
        <IconButton
          className="text-white"
          onClick={() => copyToClipboard(wallet.address)}>
          <BsCopy className="text-white" />
        </IconButton>
      </div>
      <div className="flex flex-row justify-between">
        <p>
          via {capitalize(wallet.id)}{' '}
          {wallet.type === WalletType.WALLETCONNECT ? 'Mobile' : 'Extension'}
        </p>
      </div>
      <Button
        onClick={() => {
          controller?.disconnect([DEFAULT_CHAIN]);
        }}>
        Disconnect
      </Button>
    </div>
  ) : null;
};

const ControllerSection = ({
  controller,
}: {
  controller: WalletController;
}) => {
  const [extensionInstalled, setExtensionInstalled] = useState(false);
  useEffect(() => {
    controller.isInstalled('extension').then(setExtensionInstalled);
  }, [controller]);
  const [mobileInstalled, setMobileInstalled] = useState(false);
  useEffect(() => {
    controller.isInstalled('walletconnect').then(setMobileInstalled);
  }, [controller]);
  return (
    <Fragment key={controller.id}>
      {extensionInstalled && (
        <Button
          className="flex w-28 flex-col items-center justify-evenly gap-1 rounded-xl bg-accent-btn/95 py-4"
          onClick={() => connectWallet(controller.id, 'extension')}>
          <img
            style={{ boxShadow: '0 0 4px 4px white' }}
            className="mb-2 rounded-2xl"
            src={`/assets/img/wallets/${controller.id}.webp`}
          />
          <p className="font-bold">{capitalize(controller.id)}</p>
          <p className="text-xs">Extension</p>
        </Button>
      )}
      {mobileInstalled && (
        <Button
          className="flex w-28 flex-col items-center justify-evenly gap-1 rounded-xl bg-accent-btn/95 py-4"
          onClick={() => connectWallet(controller.id, 'walletconnect')}>
          <img
            style={{ boxShadow: '0 0 4px 4px white' }}
            className="mb-2 rounded-2xl"
            src={`/assets/img/wallets/${controller.id}.webp`}
          />
          <p className="font-bold">{capitalize(controller.id)}</p>
          <p className="text-xs">Mobile App</p>
        </Button>
      )}
    </Fragment>
  );
};

export const WalletModal: FC = () => {
  const open = walletState.use.connectModalIsOpen();
  const address = walletState.use.address();

  if (address) {
    return <ConnectedWalletModal />;
  }

  return (
    <CardModal
      open={open}
      onClose={closeWalletConnectModal}
      className="relative max-h-[95dvh] w-[90dvw] sm:w-[700px] sm:max-w-[90vw]"
      title={
        <div className="flex min-w-0 flex-col items-start">
          <h1 className="text-start text-xl font-bold sm:text-4xl">
            Connect Wallet
          </h1>
          <p className="min-w-0 text-wrap text-start text-xs text-white/80">
            Use your wallet to connect into many different platforms.
          </p>
          <p className="min-w-0 text-wrap text-start text-xs text-white/80">
            No unique accounts or passwords.
          </p>
        </div>
      }
      subtitle={
        <FaX
          className="size-8 cursor-pointer self-start p-2"
          onClick={closeWalletConnectModal}
        />
      }>
      <div
        className="relative flex min-h-64 flex-col items-center rounded-b-3xl bg-[#0D1624] bg-center bg-no-repeat p-6"
        style={{
          backgroundImage: 'url(/assets/img/wallets/bg.webp)',
          backgroundPositionY: '180px',
        }}>
        <Planet
          src="PlanetMagenta01_RadialShadow_1024"
          top="-20px"
          left="-50px"
        />
        <Planet
          src="PlanetYellow01_RadialShadow_1024"
          bottom="60px"
          right="-90px"
          size="140px"
        />
        <Star size="18px" right="8%" top="25%" />
        <Star size="8px" right="6%" top="5%" />
        <Star size="8px" left="10%" bottom="40%" />
        <Star size="6px" left="6%" top="6%" />
        {address ? (
          <CurrentWallet />
        ) : (
          <div className="flex flex-wrap gap-6">
            {Object.values(controllers).map((controller) => (
              <ControllerSection key={controller.id} controller={controller} />
            ))}
          </div>
        )}
      </div>
    </CardModal>
  );
};
