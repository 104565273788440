import {
  FC,
  useState,
  useMemo,
  Fragment,
  ComponentProps,
  useEffect,
  Dispatch,
  SetStateAction,
  useRef,
} from 'react';
import { swapMsg } from 'api/messages/swap';
import { useSimulateSwapOperationQuery } from 'api/terraport/swap';
import { map, round } from 'lodash';
import { TxData, calcTax, pluggableTx, simulateTx } from 'state/contract';
import { Button, IconButton, IconToggle } from 'ui/components/button';
import { convertMicroDenomToDenom, copyToClipboard } from 'helper/utils';
import { FaRightLeft } from 'react-icons/fa6';
import clsx from 'clsx/lite';
import { BsCopy } from 'react-icons/bs';
import { getAddress, openWalletConnectModal, useAddress } from 'state/wallet-state';
import { FormattedNumber, FormattedPercent } from 'ui/components/formatted-number';
import { toast } from 'react-toastify';
import { useBalance } from 'api/rpc/balance';
import { pairState } from 'state/trade';
import { useFormik } from 'formik';
import { FormikTextInput } from 'ui/components/input';
import { invalidateQueries } from 'state/providers/query';
import { AssetChip } from './misc';
import { FaChevronCircleRight, FaCog } from 'react-icons/fa';

// TODO spostare anche tutti gli altri campi dentro il formik per ridurre un po' il componente
type SwapParams = {
  amount: number;
};

const slippageValues = [0.5, 1, 1.5, 3];
export const Slippage = ({
  slippage,
  setSlippage,
}: {
  slippage: number;
  setSlippage: Dispatch<SetStateAction<number>>;
}) => {
  const changed = useRef(false);

  const [showSlippage, setShowSlippage] = useState(false);
  const isDefaultSlippage = useMemo(() => !changed.current || slippageValues.includes(slippage), [slippage]);
  return (
    <div className="flex w-full min-w-0 flex-row flex-wrap items-center justify-between gap-2 py-1 text-xs">
      <p>Slippage tolerance:</p>
      {showSlippage ? (
        <>
          <input
            type="number"
            value={slippage}
            onChange={(e) => {
              changed.current = true;
              setSlippage(Number(e.target.value));
            }}
            className="ml-auto flex h-5 items-center justify-evenly gap-1 rounded-full bg-black/20 px-4 !text-xs text-white"
          />
          <MiniButton onClick={() => setShowSlippage(false)} className="!bg-accent-btn !text-text-accent">
            OK
          </MiniButton>
        </>
      ) : (
        <>
          <div className="ml-auto flex h-5 items-center justify-evenly gap-1 rounded-full bg-black/20 px-2 !text-xs">
            {slippageValues.map((value) => (
              <IconToggle
                key={value}
                className="!font-light"
                active={slippage === value}
                onClick={() => {
                  changed.current = true;
                  setSlippage(value);
                }}>
                {value}
              </IconToggle>
            ))}
          </div>
          <MiniButton
            onClick={() => setShowSlippage(true)}
            className={`center !font-light ${isDefaultSlippage ? 'bg-black/20' : 'bg-accent-btn text-text-accent'}`}>
            {isDefaultSlippage ? <FaCog /> : slippage}
          </MiniButton>
        </>
      )}
    </div>
  );
};

const MiniButton: FC<ComponentProps<'button'>> = ({ className, ...props }) => (
  <button className={clsx(className, 'h-5 rounded-full px-2 text-xs outline-none')} {...props} />
);

const TextChip: FC<ComponentProps<'p'> & { copy?: boolean }> = ({
  className,
  children,
  copy = false,
  ...props
}) => (
  <div
    onClick={() => {
      if (copy) {
        toast.info('Copied to clipboard', {});
        copyToClipboard(children as string);
      }
    }}
    className={clsx(
      'overflow-hidden text-ellipsis whitespace-nowrap',
      'flex flex-row items-center justify-between rounded-lg bg-white/10 px-2 py-1 text-xs',
      'cursor-pointer',
      className,
    )}
    {...props}>
    {children}
    {copy && <BsCopy className="min-w-4 text-accent-btn" />}
  </div>
);

export const SwapSection: FC = () => {
  const [slippage, setSlippage] = useState(50);

  const fromAsset = pairState.use.fromAsset();
  const toAsset = pairState.use.toAsset();

  const address = useAddress();

  const balance = useBalance(fromAsset);

  const [txData, setTxData] = useState<TxData | undefined>();

  const formik = useFormik<SwapParams>({
    initialValues: {
      amount: 1,
    },
    onSubmit: async () => null,
  });
  const { data, error: routerError } = useSimulateSwapOperationQuery(
    fromAsset,
    toAsset,
    formik.values.amount,
  );

  const setAmount = (value: number) => {
    formik.setFieldValue('amount', value);
  };

  useEffect(() => {
    // IIFE because I'm lazy
    (async () => {
      if (data && address && fromAsset && toAsset) {
        const amount = formik.values.amount.toString();
        simulateTx(
          [
            await swapMsg({
              fromAsset,
              fromAmount: amount,
              swap_operations: data?.simulate_swap_message.simulate_swap_operations,
            }),
          ],
          await calcTax([{ value: amount, asset: fromAsset }]),
        ).then(setTxData);
      }
    })();
  }, [data, address]);

  const executeSwap = async () => {
    if (address) {
      if (data && fromAsset && toAsset) {
        const amount = round(formik.values.amount, fromAsset?.decimals ?? 6).toString();
        await pluggableTx(
          [
            await swapMsg({
              fromAsset,
              fromAmount: amount,
              swap_operations: data?.simulate_swap_message.simulate_swap_operations,
            }),
          ],
          {
            tax: await calcTax([{ value: amount, asset: fromAsset }]),
            children: (
              <>
                <FormattedNumber value={Number(amount)} className="" />
                <img src={fromAsset.img} className="!size-5 rounded-full" />
                <FaChevronCircleRight className="text-subtitle" />
                <FormattedNumber value={convertMicroDenomToDenom(data?.simulation.amount)} className="" />
                <img src={toAsset.img} className="!size-5 rounded-full" />
              </>
            ),
            success: 'Swap Successful',
          },
        ).then(() => {
          invalidateQueries([{ queryKey: ['balance'] }]);
        });
        setAmount(0);
      }
    } else {
      getAddress();
    }
  };
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (formik.values.amount > balance) {
      setError('Insufficient Funds');
    } else {
      setError(null);
    }
  }, [formik.values, balance]);

  const fee = useMemo(
    () =>
      map(txData?.fee.amount, ({ denom, amount }) => ({
        denom:
          denom === 'uluna' ? 'LUNC' : fromAsset?.microdenom === denom ? fromAsset.denom : toAsset?.denom,
        amount: convertMicroDenomToDenom(amount),
      })),
    [txData?.fee.amount],
  );

  return (
    <>
      <div className="flex w-full min-w-0 flex-row flex-wrap items-center justify-between gap-2 text-xs">
        <FormattedNumber
          prefix={<span className="mr-1 text-white/80">Balance: </span>}
          value={balance || 0}
          suffix={fromAsset?.denom}
        />
        {balance > 0 && (
          <div className="flex flex-row gap-1">
            <MiniButton
              onClick={() => {
                setAmount(round(0.25 * balance, fromAsset?.decimals ?? 6));
              }}>
              25%
            </MiniButton>
            <MiniButton
              onClick={() => {
                setAmount(round(0.5 * balance, fromAsset?.decimals ?? 6));
              }}>
              50%
            </MiniButton>
            <MiniButton
              onClick={() => {
                setAmount(
                  round(
                    (fromAsset?.contract_addr === 'uluna' ? 0.97 : 0.99) * balance,
                    fromAsset?.decimals ?? 6,
                  ),
                );
              }}>
              Max
            </MiniButton>
          </div>
        )}
      </div>
      <AssetChip asset={fromAsset}>
        <p className="text-sm">Pay</p>
        <FormikTextInput
          formik={formik}
          name="amount"
          label=""
          type="number"
          placeholder="Amount"
          inputClassname="bg-transparent text-white outline-none border-none text-end min-w-0 w-full !p-1"
        />
        {/* <input type="number" value={amount} onFocus={handleClearValue} onChange={updateValue} className='bg-transparent text-xl text-white outline-none border-none text-end min-w-0 w-full'/> */}
        <p>
          <FormattedNumber
            className="text-sm text-subtitle"
            prefix="≈$"
            value={data?.simulation.source_dollar_value}
          />
        </p>
      </AssetChip>
      <div className="mt-4 box-content flex w-full flex-col items-center rounded-b-2xl bg-gradient-to-r from-gr1 to-gr2 p-4 pt-6">
        <div className="relative w-full">
          <div
            className="center absolute -top-[40px] left-0 right-0 bg-center bg-no-repeat p-1"
            style={{ backgroundImage: 'url(/assets/svg/bubble-horiz.svg)' }}>
            <IconButton onClick={pairState.set.toggleReverse}>
              <FaRightLeft className="rotate-90" />
            </IconButton>
          </div>
        </div>
        <AssetChip asset={toAsset}>
          <p className="text-sm">Receive</p>
          <FormattedNumber
            className="w-full min-w-0 justify-end text-ellipsis border-none p-1 text-white outline-none"
            value={convertMicroDenomToDenom(data?.simulation.amount ?? 0, toAsset?.decimals)}
          />
          <p className="center gap-1">
            <FormattedNumber
              className="text-sm text-subtitle"
              prefix={'≈$'}
              value={data?.simulation.dest_dollar_value}
            />
            <FormattedPercent
              className="text-sm"
              prefix=" ("
              value={data?.simulation.delta_percent}
              suffix=")"
            />
          </p>
        </AssetChip>
        <p className="px-2 pt-1 text-[10px]">
          The displaying number is the simulated result and can be different from the actual swap rate. Trade
          at your own risk.
        </p>
      </div>
      <Slippage slippage={slippage} setSlippage={setSlippage} />
      <TextChip className="box-border w-full flex-row flex-wrap justify-between rounded-lg !px-4">
        {data && (
          <p>
            <FormattedNumber
              prefix={`1 ${fromAsset?.denom} = `}
              value={data.simulation.ratio}
              suffix={toAsset?.denom}
            />{' '}
          </p>
        )}
        {txData && (
          <p className="center gap-1">
            Fee:{' '}
            {map(fee, (coin) => (
              <Fragment key={coin.denom}>
                <FormattedNumber value={coin.amount} /> {coin.denom}
              </Fragment>
            ))}
          </p>
        )}
      </TextChip>
      {routerError && <TextChip className="w-full text-theme-red">{routerError.message}</TextChip>}
      {address ? (
        error ? (
          <div className="center mt-auto h-12 w-full rounded-xl bg-black/40 font-bold text-theme-red">
            {error}
          </div>
        ) : (
          <Button
            disabled={!(formik.values.amount > 0)}
            onClick={executeSwap}
            className="mt-auto h-12 w-full rounded-xl">
            SWAP
          </Button>
        )
      ) : (
        <Button
          onClick={openWalletConnectModal}
          className="mt-auto h-12 w-full rounded-xl bg-gradient-to-t from-gr1 to-gr2 text-white">
          CONNECT
        </Button>
      )}
    </>
  );
};

export default SwapSection;
