import { formatPositiveInteger } from 'helper/utils';
import { FC, useEffect, useState } from 'react';

export const useCountdown = (targetDate?: Date) => {
  const countDownDate = targetDate?.getTime() ?? 0;

  const [countDown, setCountDown] = useState(
    countDownDate - new Date().getTime(),
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(countDownDate - new Date().getTime());
    }, 1000);

    return () => clearInterval(interval);
  }, [countDownDate]);

  if (!targetDate) return getReturnValues(0);
  return getReturnValues(countDown);
};

const getReturnValues = (countDown: number) => {
  // calculate time left
  const countDownInSeconds = countDown / 1000;
  const days = formatPositiveInteger(countDownInSeconds / (3600 * 24));
  const hours = formatPositiveInteger((countDownInSeconds / 3600) % 24);
  const minutes = formatPositiveInteger((countDownInSeconds / 60) % 60);
  const seconds = formatPositiveInteger(countDownInSeconds % 60);

  return [days, hours, minutes, seconds];
};

type Props = {
  date: Date;
};

const DateTimeDisplay = ({ value, type, isDanger }) => {
  return (
    <div className={isDanger ? 'countdown danger' : 'countdown'}>
      <p>{value}</p>
      <span>{type}</span>
    </div>
  );
};

export const CountdownShower: FC<Props> = ({ date }) => {
  const countdown = useCountdown(date);

  if (!countdown) return null;

  return (
    <>
      <div className="flex flex-row gap-1">
        <DateTimeDisplay
          value={countdown[0]}
          type={'Days'}
          isDanger={countdown[0] <= 3}
        />
        <p>:</p>
        <DateTimeDisplay value={countdown[1]} type={'Hours'} isDanger={false} />
        <p>:</p>
        <DateTimeDisplay value={countdown[2]} type={'Mins'} isDanger={false} />
        <p>:</p>
        <DateTimeDisplay value={countdown[3]} type={'Secs'} isDanger={false} />
      </div>
    </>
  );
};

export const CountdownShower2: FC<Props> = ({ date }) => {
  const countdown = useCountdown(date);

  return (
    <>
      <div className="my-auto flex flex-col items-center self-center rounded-xl bg-background2 text-gr2">
        <p className="px-4 text-8xl font-bold">{countdown[0]}</p>
        <p className="px-4 text-3xl font-bold">DAYS</p>
        <p className="center w-full min-w-[190px] rounded-b-xl bg-background1 px-2 py-2 text-4xl font-bold">
          {countdown[1]}:{countdown[2]}:{countdown[3]}
        </p>
      </div>
    </>
  );
};
