import { ComponentProps, FC, Fragment, ReactNode } from 'react';
import clsx from 'clsx/lite';
import { Transition } from '@headlessui/react';

export const Card: FC<
  Omit<ComponentProps<'section'>, 'title'> & {
    title: ReactNode;
    subtitle?: ReactNode;
    contentClassName?: string;
    subtitleClassName?: string;

    titleClassName?: string;
    isLoading?: boolean;
  }
> = ({
  children,
  title,
  subtitle = null,
  className,
  contentClassName,
  subtitleClassName,
  isLoading = false,
  titleClassName,
  ...sectionProps
}) => (
  <section
    className={clsx(
      'relative flex animate-fade-down flex-col items-stretch justify-start rounded-3xl bg-background3 text-white elevation-12',
      className,
    )}
    {...sectionProps}>
    <div className="flex min-w-0 flex-row items-stretch justify-start overflow-hidden rounded-t-3xl">
      <div
        className={clsx(
          'relative min-w-0 content-center rounded-tl-3xl bg-gradient-to-r from-gr1 to-gr2 py-3 pl-6 text-lg font-bold uppercase',
          titleClassName,
        )}>
        {title}
        <div
          className="absolute -right-[99px] bottom-0 top-0 w-[100px] bg-gr2"
          style={{ clipPath: 'url(#card-end)' }}
        />
      </div>
      {subtitle && (
        <div
          className={clsx(
            'z-2 absolute right-1 top-1 ml-auto flex min-h-fit content-center items-center justify-center p-2',
            subtitleClassName,
          )}>
          {subtitle}
        </div>
      )}
    </div>
    <div className={clsx(isLoading && 'animate-pulse', contentClassName)}>
      {children}
    </div>
  </section>
);
