import clsx from 'clsx';
import { FC } from 'react';
import { CardModal } from 'ui/components/modal';
import { SwapContent } from './content';
import { FaChevronUp, FaX } from 'react-icons/fa6';
import { createStore } from 'zustand-x';
import { LUNC, TERRA } from 'consts/common-tokens';
import { createPortal } from 'react-dom';

export const quickSwap = createStore('quick-swap')({
  fromAsset: LUNC,
  toAsset: TERRA,
  open: false,
});

export const QuickSwapButton = ({ className }: { className?: string }) => {
  const open = quickSwap.use.open();
  return createPortal(
    <div
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        quickSwap.set.open(true);
      }}
      className={clsx(
        'absolute bottom-0 right-4 z-30 transition-all duration-500',
        open && 'pointer-events-none translate-x-8 opacity-0',
        'hidden lg:flex',
        'bg-gradient-to-l from-gr1 to-gr2 text-lg font-bold text-white',
        'items-center',
        'gap-4 rounded-t-xl px-4 py-2',
        'flex-row overflow-hidden',
        className,
      )}>
      <img
        src="/assets/img/trade/swap.webp"
        className="-m-4 size-16 object-cover"
      />
      SWAP
      <FaChevronUp />
    </div>,
    document.body,
  );
};

export const Swapper: FC = () => {
  const open = quickSwap.use.open();
  return (
    <>
      <CardModal
        animation="animate-fade-up"
        title="Swap"
        open={open}
        onClose={() => quickSwap.set.open(false)}
        subtitle={
          <FaX
            className="size-8 cursor-pointer self-start p-2 text-white"
            onClick={() => quickSwap.set.open(false)}
          />
        }
        contentClassName="p-4 center flex-col gap-2 max-w-[400px]"
        titleClassName="w-full max-w-[260px] text-start !text-3xl">
        <SwapContent />
      </CardModal>
    </>
  );
};

export default Swapper;
