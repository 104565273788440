import {
  InvalidateQueryFilters,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { forEach } from 'lodash';
import { FC, ReactNode } from 'react';

export const qC = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 2,
      retryDelay: 3000,
      staleTime: 5 * 60 * 1000, //5m
      refetchOnWindowFocus: false,
    },
  },
});

export const QueryProvider: FC<{ children: ReactNode }> = ({ children }) => (
  <QueryClientProvider client={qC}>{children}</QueryClientProvider>
);

export const invalidateQueries = (keys: InvalidateQueryFilters[]) =>
  setTimeout(() => forEach(keys, (key) => qC.invalidateQueries(key)), 20);
