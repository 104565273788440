import { convertDenomToMicroDenom, hasTaxToken } from '../../helper/utils';
import { walletState } from '../../state/wallet-state';
import { MsgExecuteContract } from 'cosmes/client';
import { CosmosBaseV1beta1Coin as Coin } from 'cosmes/protobufs';
import { loadTaxInfo } from '../api';
import { Asset } from 'types/pairs';
import { reverse, round } from 'lodash';

export const increaseAllowanceMsg = ({
  tokenContract,
  spender,
  amount,
}: {
  tokenContract: string;
  spender: string;
  amount: string;
}) => {
  return new MsgExecuteContract({
    sender: walletState.get.address()!,
    contract: tokenContract,
    msg: {
      increase_allowance: {
        amount,
        spender,
        expires: { never: {} },
      },
    },
    funds: [],
  });
};

export const removeLiquidityMsg = ({
  pairContract,
  lpTokenContract,
  amount,
}: {
  pairContract: string;
  lpTokenContract: string;
  amount: number;
}) => {
  const msg = {
    withdraw_liquidity: {},
  };
  return new MsgExecuteContract({
    sender: walletState.get.address()!,
    contract: lpTokenContract,
    msg: {
      send: {
        contract: pairContract,
        amount: convertDenomToMicroDenom(amount),
        msg: btoa(JSON.stringify(msg)),
      },
    },
    funds: [],
  });
};
// Native Token vs Token
export const addLiquidityMsg = ({
  pairContract,
  fromValue,
  toValue,
  from,
  to,
  isV3,
  slippage,
}: {
  pairContract: string;
  fromValue: string;
  toValue: string;
  from: Asset;
  to: Asset;
  isV3: boolean;
  slippage: number;
}) => {
  const asset1 = from?.contract_addr?.startsWith('terra')
    ? {
        info: {
          token: {
            contract_addr: from.contract_addr,
          },
        },
        amount: fromValue,
      }
    : {
        info: {
          native_token: {
            denom: from.contract_addr,
          },
        },
        amount: fromValue,
      };
  const asset2 = to?.contract_addr?.startsWith('terra')
    ? {
        info: {
          token: {
            contract_addr: to.contract_addr,
          },
        },
        amount: toValue,
      }
    : {
        info: {
          native_token: {
            denom: to.contract_addr,
          },
        },
        amount: toValue,
      };
  const coins = [];

  if (from.type === 'native') {
    coins.push(
      new Coin({
        denom: from.contract_addr,
        amount: fromValue,
      }),
    );
  }
  if (to.type === 'native') {
    coins.push(
      new Coin({
        denom: to.contract_addr,
        amount: toValue,
      }),
    );
  }
  //fix for ibc, no one knows, no touchy
  if (coins[1]?.denom?.startsWith('ibc/')) {
    reverse(coins);
  }
  const msg = {
    provide_liquidity: {
      assets: [asset1, asset2],
    },
  };
  if (isV3) {
    msg.provide_liquidity.slippage_tolerance = round(
      slippage / 100,
      2,
    ).toString();
  }
  return new MsgExecuteContract({
    sender: walletState.get.address()!,
    contract: pairContract,
    msg,
    funds: coins,
  });
};

export const addLiquidityMsgs = ({
  pairContract,
  fromAmount,
  toAmount,
  fromAsset,
  toAsset,
  isV3,
  slippage,
}: {
  pairContract: string;
  fromAmount: string | number;
  toAmount: string | number;
  fromAsset: Asset;
  toAsset: Asset;
  isV3: boolean;
  slippage: number;
}) => {
  const fromValue = convertDenomToMicroDenom(fromAmount, fromAsset.decimals);
  const toValue = convertDenomToMicroDenom(toAmount, toAsset.decimals);
  const msgs = [];
  if (fromAsset?.contract_addr?.startsWith('terra')) {
    msgs.push(
      increaseAllowanceMsg({
        tokenContract: fromAsset?.contract_addr,
        spender: pairContract,
        amount: fromValue,
      }),
    );
  }
  if (toAsset?.contract_addr?.startsWith('terra')) {
    msgs.push(
      increaseAllowanceMsg({
        tokenContract: toAsset?.contract_addr,
        spender: pairContract,
        amount: toValue,
      }),
    );
  }
  msgs.push(
    addLiquidityMsg({
      pairContract,
      fromValue,
      toValue,
      from: fromAsset,
      to: toAsset,
      isV3,
      slippage,
    }),
  );

  return msgs;
};

export const calcLiquidityTax = async (
  value1: string,
  asset1: Asset,
  value2: string,
  asset2: Asset,
) => {
  const tax: Coin[] = [];
  const taxRate = walletState.get.taxRate();
  const taxCap1 = await loadTaxInfo(asset1.contract_addr);
  const taxCap2 = await loadTaxInfo(asset2.contract_addr);
  if (taxCap1 && hasTaxToken(asset1.contract_addr)) {
    tax.push(
      new Coin({
        denom: asset1.contract_addr,
        amount: `${Math.ceil(Math.min(Number(taxCap1), Number(convertDenomToMicroDenom(value1)) * Number(taxRate)))}`,
      }),
    );
  }
  if (taxCap2 && hasTaxToken(asset2.contract_addr)) {
    tax.push(
      new Coin({
        denom: asset2.contract_addr,
        amount: `${Math.ceil(Math.min(Number(taxCap2), Number(convertDenomToMicroDenom(value2)) * Number(taxRate)))}`,
      }),
    );
  }
  return tax;
};
