import { Adapter } from 'cosmes/client';
import { UnsignedTx } from 'cosmes/wallet';
import { Id, toast } from 'react-toastify';
import { classicNetwork } from '../consts/networks';
import { walletState } from './wallet-state';
import { Coin, Fee } from '../types/wallet';
import { loadTaxInfo } from '../api/api';
import { convertDenomToMicroDenom, hasTaxToken } from '../helper/utils';
import { Asset } from 'types/pairs';
import { getContractConfig } from 'api/rpc/query-contract';
import { invalidateQueries } from './providers/query';
import { ReactNode } from 'react';
import { isError, merge } from 'lodash';
import { txErrorToast, txPendingToast, txSuccessToast } from 'ui/components/toasts';

export const getGovernanceConfig = async () => {
  return await getContractConfig(classicNetwork.governanceContract);
};

// split in two
export const simulateTx = async (msgs: Array<Adapter | null>, tax?: Coin[]) => {
  const wallet = walletState.get.wallet();
  if (wallet) {
    try {
      const tx: UnsignedTx = {
        msgs: msgs.filter((msg) => msg !== null) as Adapter[],
      };
      const fee = await wallet.estimateFee(tx, 2);
      const feeWithTax = addTax(fee, tax);

      return { fee: feeWithTax, tx };
    } catch (error) {
      if (!(error instanceof Error)) return;
      console.error(`There was an error while preparing the transaction: ${error.message}`);
    }
  }
};
export type TxData = Awaited<ReturnType<typeof simulateTx>>;

// all at once
export const broadcastTx = async (msgs: Array<Adapter | null>, tax?: Coin[], memo?: string) => {
  const tx: UnsignedTx = {
    memo,
    msgs: msgs.filter((msg) => msg !== null) as Adapter[],
  };
  const wallet = walletState.get.wallet();
  if (wallet) {
    const toastId = toast.loading('Preparing transaction');
    try {
      const fee = await wallet.estimateFee(tx, 2);
      toast.update(toastId, { render: 'Waiting for user approval' });
      const feeWithTax = addTax(fee, tax);
      const hash = await wallet.broadcastTx(tx, feeWithTax);
      toast.update(toastId, { render: 'Waiting for transaction confirmation' });
      const postedTx = await wallet.pollTx(hash);
      toast.dismiss(toastId);
      if (postedTx.txResponse.code === 0) {
        toast.success('Operation Successful', { autoClose: 1500 });
      }
      invalidateQueries([{ queryKey: ['balance'] }]);
      return postedTx;
    } catch (error) {
      console.error(error);
      toast.dismiss(toastId);
      if (!(error instanceof Error)) return;
      if (error.message.includes('Overflow: Cannot Sub')) {
        toast.error('Your balance is too low');
      } else {
        toast.error(error.message, {});
      }
    }
  }
};

const addTax = (fee: Fee, tax: Coin[] = []) => {
  const amount: Coin[] = tax.reduce((acc, coin) => {
    const feeIndex = acc.findIndex((c) => c.denom === coin.denom);
    if (feeIndex >= 0) {
      acc[feeIndex] = new Coin({
        amount: `${Math.ceil(Number(acc[feeIndex].amount) + Number(coin.amount) * 1.2)}`,
        denom: coin.denom,
      });
    } else {
      acc.push(coin);
    }
    return acc;
  }, fee.clone().amount);
  // const amount = [...fee.amount, ...tax];
  return new Fee({ ...fee, amount });
};

export const calcTax = async (assets: Array<{ asset: Asset; value: string }>): Promise<Coin[]> => {
  const taxRate = walletState.get.taxRate();
  const tax: Coin[] = [];
  for (const { asset, value } of assets) {
    const taxCap = await loadTaxInfo(asset.contract_addr);
    if (taxCap && hasTaxToken(asset.contract_addr)) {
      tax.push(
        new Coin({
          denom: asset.contract_addr,
          amount: `${Math.round(Math.min(Number(taxCap), Number(convertDenomToMicroDenom(value, asset.decimals)) * Number(taxRate)))}`,
        }),
      );
    }
  }
  return tax;
};

type TxOptions = {
  tax?: Coin[];
  memo?: string;
  toasts?: {
    success?: string | ReactNode;
    error?: string | ReactNode;
    pending?: string | ReactNode;
    polling?: string | ReactNode;
  };
};

export const simTx = async (msgs: Array<Adapter | null>) => {
  const tx: UnsignedTx = {
    msgs: msgs.filter((msg) => msg !== null) as Adapter[],
  };
  const wallet = walletState.get.wallet();
  return await wallet?.estimateFee(tx, 2);
};

export const postTx = async (msgs: Array<Adapter | null>, options?: TxOptions) => {
  const tx: UnsignedTx = {
    memo: options?.memo,
    msgs: msgs.filter((msg) => msg !== null) as Adapter[],
  };
  const wallet = walletState.get.wallet();
  const tax = options?.tax ?? [];
  const toasts = merge(
    {
      success: 'Operation Successful',
      error: '',
      pending: 'Preparing transaction',
      waiting: 'Waiting for user approval',
      polling: 'Waiting for transaction confirmation',
    },
    options?.toasts,
  );
  if (wallet) {
    const toastId = toast.loading(toasts.pending);
    try {
      const fee = await wallet.estimateFee(tx, 2);
      toast.update(toastId, { render: toasts.waiting });
      const feeWithTax = addTax(fee, tax);
      const hash = await wallet.broadcastTx(tx, feeWithTax);
      toast.update(toastId, { render: toasts.polling });
      const txData = await wallet.pollTx(hash);
      toast.dismiss(toastId);
      if (txData) {
        toast.success(toasts.success, { autoClose: 1500 });
        invalidateQueries([{ queryKey: ['balance'] }]);
      }
      return { hash, txData };
    } catch (error) {
      console.error(error);
      toast.dismiss(toastId);
      if (error.message.includes('Overflow: Cannot Sub')) {
        toast.error('Your balance is too low');
      } else {
        toast.error(error.message, {});
      }
      return Promise.reject(error);
    }
  }
  return Promise.reject('Wallet not connected');
};

type PluggableTxOptions = {
  tax?: Coin[];
  memo?: string;
  success: string;
  children: ReactNode;
  onSign?: (hash: string) => void;
};

const dismiss = (id?: Id | null) => {
  if (id) {
    toast.dismiss(id);
  }
};

export const pluggableTx = async (msgs: Array<Adapter | null>, options: PluggableTxOptions) => {
  const { tax, memo, success, children, onSign } = options;
  const tx: UnsignedTx = {
    memo,
    msgs: msgs.filter((msg) => msg !== null) as Adapter[],
  };
  const wallet = walletState.get.wallet();
  let toastId;
  if (wallet) {
    try {
      console.log(tx);
      toastId = txPendingToast({ children, message: 'Preparing transaction' });
      const fee = await wallet.estimateFee(tx, 2);
      dismiss(toastId);
      toastId = txPendingToast({ children, message: 'Waiting for user' });
      const feeWithTax = addTax(fee, tax ?? []);
      const hash = await wallet.broadcastTx(tx, feeWithTax);
      onSign?.(hash);
      dismiss(toastId);
      toastId = txPendingToast({
        children,
        message: 'Waiting for confirmation',
      });
      const txData = await wallet.pollTx(hash);
      dismiss(toastId);
      if (txData) {
        txSuccessToast({ children, message: success, tx: hash });
        invalidateQueries([{ queryKey: ['balance'] }]);
      }
      return { hash, txData };
    } catch (error) {
      console.error(error);
      toast.dismiss(toastId);
      if (error?.message?.includes('Overflow: Cannot Sub')) {
        txErrorToast({ children, error: new Error('Insufficient balance') });
      } else {
        txErrorToast({
          children,
          error: isError(error) ? error : new Error(error),
        });
      }
      return Promise.reject(error);
    }
  }
  return Promise.reject('Wallet not connected');
};
