import clsx from 'clsx/lite';
import { ComponentProps, FC, ReactNode, useMemo } from 'react';
import { useMDBreakpoint } from 'helper/responsive';

export const roundToDecimals = (n: number, decimals: number = 2) => {
  const log10 = n ? Math.floor(Math.log10(n)) : 0;
  const div = log10 < 0 ? Math.pow(10, decimals - log10 - 1) : Math.pow(10, decimals);
  return Math.round(n * div) / div;
};

const intl = new Intl.NumberFormat(navigator.languages, {
  // minimumSignificantDigits: 2,
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
});

const fractionalIntl = new Intl.NumberFormat(navigator.languages, {
  // minimumSignificantDigits: 2,
  maximumSignificantDigits: 5,
});

const mobileFractionalIntl = new Intl.NumberFormat(navigator.languages, {
  // minimumSignificantDigits: 2,
  maximumSignificantDigits: 2,
});

const dollarIntl = new Intl.NumberFormat(navigator.languages, {
  style: 'currency',
  currency: 'USD',
  currencyDisplay: 'narrowSymbol',
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
  trailingZeroDisplay: 'stripIfInteger',
});
export const formatNumberForLinkPreview = (value: number | null | undefined) => {
  if (value === null || value === undefined) return '-';
  if (value === 0) return '0';

  // Handle billions by putting a B at the end
  if (value > 1000000000) {
    return `${intl.format(Number((value / 1000000000).toFixed(2)))} B`;
  }
  // Handle millions by putting an M at the end
  if (value > 1000000) {
    return `${intl.format(Number((value / 1000000).toFixed(2)))} M`;
  }
  // Handle thousands by putting an M at the end
  if (value > 1000) {
    return `${intl.format(Number((value / 1000).toFixed(2)))} k`;
  }
  // Handle number smaller than 0.0001
  if (value < 0.001) {
    // expand all decimals to avoid scientific notation
    const string = value.toFixed(40);

    // split the string into parts
    const parts = string.split('.');

    // find the number of zeros after the decimal point
    const zeros = parts[1].match(/0+/);
    const zerosLength = zeros ? zeros[0].length : 0;
    return value.toFixed(Math.max(zerosLength + 3, 2));
  }
  return intl.format(Number(value));
};

export const FormattedNumber: FC<
  Omit<ComponentProps<'span'>, 'prefix'> & {
    subClassName?: string;
    extended?: boolean;
    value: number | null | undefined;
    prefix?: ReactNode;
    suffix?: ReactNode;
    signed?: boolean;
    compact?: boolean;
  }
> = ({
  compact = false,
  subClassName = '',
  extended = false,
  value = null,
  prefix = '',
  suffix = '',
  className,
  signed = false,
  ...rest
}) => {
  const isMobile = useMDBreakpoint();
  const formatted = useMemo(() => {
    if (value === null || value === undefined || isNaN(value)) return '-';
    if (value === 0) return '0';

    if ((isMobile || compact) && !extended) {
      // Handle billions by putting a B at the end
      if (value >= 1000000000) {
        return `${intl.format(Number((value / 1000000000).toFixed(2)))} B`;
      }
      // Handle millions by putting an M at the end
      if (value >= 1000000) {
        return `${intl.format(Number((value / 1000000).toFixed(2)))} M`;
      }
      // Handle thousands by putting an M at the end
      if (value >= 1000) {
        return `${intl.format(Number((value / 1000).toFixed(2)))} k`;
      }
      if (value < 1) {
        const intled = mobileFractionalIntl.format(value);
        const zeros = intled.substring(2).match(/0+/)?.[0];
        if (zeros && zeros.length > 2) {
          const [pre, post] = intled.split(zeros);
          return (
            <>
              {pre}0<sub className={clsx('self-end text-[70%]', subClassName)}>{zeros.length}</sub>
              {post}
            </>
          );
        }
        return intled;
      }
    }
    // Handle number smaller than 0.0001
    if (value < 1) {
      const intled = fractionalIntl.format(value);
      const zeros = intled.substring(2).match(/0+/)?.[0];
      if (zeros && zeros.length > 2) {
        const [pre, post] = intled.split(zeros);
        return (
          <>
            {pre}0<sub className={clsx('self-end text-[70%]', subClassName)}>{zeros.length}</sub>
            {post}
          </>
        );
      }
      return intled;
      // // expand all decimals to avoid scientific notation
      // const fixedString = value.toFixed(40);

      // // split the string into parts
      // const parts = fixedString.split('.');

      // // find the number of zeros after the decimal point
      // const zeros = (parts[1].match(/0+/));
      // const zerosLength = zeros ? zeros[0].length : 0;

      // // trim the remaining numbers to 4 characters
      // const rest = parts[1].slice(zerosLength).substring(0, 2);
      // // if there are less than 4 zeros, show the number with the zeros and 4 numbers
      // if (zerosLength < 3) {
      //   return value.toFixed(Math.max(zerosLength + 2, 2));
      // }

      // // if there are 4 or more zeros, show the integer part, the first zero, the count of zeros and the rest trimmed to 4 characters
      // return <>{parts[0]}.0<sub className="text-[70%] self-end">{zerosLength}</sub>{rest}</>;
    }

    // Handle the rest of the numbers by falling back to the default behavior
    return intl.format(Number(value));
  }, [value, subClassName, extended, isMobile]);
  return (
    <span
      style={{ flexFlow: 'nowrap' }}
      className={clsx('flex h-fit min-w-0 flex-nowrap items-center', className)}
      {...rest}>
      {prefix} {signed && Number(value) >= 0 && '+'}
      {formatted} {suffix}
    </span>
  );
};

const percentFormatter = new Intl.NumberFormat(navigator.languages, {
  // minimumSignificantDigits: 2,
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
});

export const FormattedPercent: FC<
  ComponentProps<'span'> & {
    value: number | null | undefined;
    prefix?: ReactNode;
    suffix?: ReactNode;
    signed?: boolean;
  }
> = ({ value = null, prefix = '', suffix = '', className, signed, ...rest }) => {
  const formatted = useMemo(() => {
    if (value === null || value === undefined) return '0';
    if (value === 0) return '0';

    // // Handle number smaller than 0.0001
    // if (value < 0.001) {

    //   // expand all decimals to avoid scientific notation
    //   const string = value.toFixed(40);

    //   // split the string into parts
    //   const parts = string.split('.');

    //   // find the number of zeros after the decimal point
    //   const zeros = (parts[1].match(/0+/));
    //   const zerosLength = zeros ? zeros[0].length : 0;

    //   // trim the remaining numbers to 4 characters
    //   const rest = parts[1].slice(zerosLength).substring(0, 3);

    //   // if there are less than 4 zeros, show the number with the zeros and 4 numbers
    //   if (zerosLength < 3) {
    //     return value.toFixed(Math.max(zerosLength + 3, 2));
    //   }

    //   // if there are 4 or more zeros, show the integer part, the first zero, the count of zeros and the rest trimmed to 4 characters
    //   return <>{parts[0]}.0<span className="text-[calc(max(40%,8px))] mb-[-12px] font-extralight ml-[1px] mr-[2px]">{zerosLength}</span>{rest}</>;
    // }

    // Handle the rest of the numbers by falling back to the default behavior
    return percentFormatter.format(Number(value));
  }, [value]);
  return (
    <span className={clsx('flex h-fit items-center', className)} {...rest}>
      {prefix} {signed && Number(value) >= 0 && '+'}
      {formatted} % {suffix}
    </span>
  );
};

export const FormattedDollar: FC<
  Omit<ComponentProps<'span'>, 'prefix'> & {
    subClassName?: string;
    extended?: boolean;
    value: number | null | undefined;
    signed?: boolean;
    compact?: boolean;
  }
> = ({ value = null, className, signed = false, ...rest }) => {
  const formatted = useMemo(() => {
    if (value === null || value === undefined || isNaN(value)) return '-';
    return dollarIntl.format(Number(value));
  }, [value]);
  return (
    <span
      style={{ flexFlow: 'nowrap' }}
      className={clsx('flex h-fit min-w-0 flex-nowrap items-center', className)}
      {...rest}>
      {signed && Number(value) >= 0 && '+'}
      {formatted}
    </span>
  );
};
