import { CODE_ID } from 'consts/misc';
import { convertDenomToMicroDenom } from 'helper/utils';
import { MsgExecuteContract, MsgInstantiateContract } from 'cosmes/client';
import { addDays } from 'date-fns';
import { walletState } from 'state/wallet-state';
import { PairInfo, Token } from 'types/pairs';

export const createFarmMsg = ({
  pair,
  rewardToken,
}: {
  pair: PairInfo;
  rewardToken: Token;
}) => {
  const address = walletState.get.address()!;

  return new MsgInstantiateContract({
    sender: address,
    admin: address,
    codeId: BigInt(CODE_ID.FARM),
    msg: {
      owner: address,
      terra_token: rewardToken.contract_addr,
      staking_token: pair.liquidity_token,
      staking_token_decimals: 6,
      governance_contract: address,
    },
    funds: [],
    label: `${pair.asset_infos.map((t) => t.denom).join('_')}_farm`,
  });
};

export const activateFarmMsg = ({
  reward_token,
  farm_address,
  amount,
  days,
}: {
  reward_token: Token;
  farm_address: string;
  amount: number;
  days: number;
}) => {
  const address = walletState.get.address()!;
  const uamount = convertDenomToMicroDenom(amount, reward_token.decimals);
  return new MsgExecuteContract({
    sender: address,
    funds: [],
    contract: reward_token.contract_addr,
    msg: {
      send: {
        msg: btoa(
          JSON.stringify({
            update_reward_schedule: {
              period_start: Math.round(Date.now() / 1000),
              period_finish: Math.round(
                addDays(Date.now(), days).getTime() / 1000,
              ),
              amount: uamount,
            },
          }),
        ),
        amount: uamount,
        contract: farm_address,
      },
    },
  });
};
