import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { LoaderRocket } from 'ui/components/loader';

export default function Pending({ small }: { small?: boolean }) {
  useEffect(() => {
    const timer = setTimeout(() => {
      toast.info(
        <p>
          We apologize for the inconvenience, an error has occurred and the page
          will be reloaded.
        </p>,
      );
      setTimeout(() => location.reload(), 3000);
    }, 60000);

    return () => clearTimeout(timer);
  }, []);

  return <LoaderRocket small={small} />;
}
