import clsx from 'clsx/lite';
import { ComponentProps, FC, MouseEvent, useState } from 'react';
import { Link } from 'react-router-dom';

// TODO feature loading per evitare che l'utente posso spammare sui bottone
// serve capire come mostrarlo graficamente

type ButtonProps = ComponentProps<'button'> & { withCooldown?: boolean };

export const Button: FC<ButtonProps> = ({
  className,
  withCooldown,
  ...props
}) => {
  const [isInCooldown, setIsInCooldown] = useState(false);

  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    props.onClick?.(e);
    if (withCooldown) {
      setIsInCooldown(true);
      setTimeout(() => {
        setIsInCooldown(false);
      }, 5000);
    }
  };

  return (
    <button
      {...props}
      className={clsx(
        'rounded-full bg-accent2 px-4 py-2 font-semibold text-text-accent transition-all hover:bg-accent-btn focus:bg-accent-btn/80 focus:shadow-accent-btn disabled:bg-accent-btn/40',
        className,
      )}
      onClick={handleClick}
      disabled={props.disabled || isInCooldown}
    />
  );
};

export const IconButton: FC<ComponentProps<'button'>> = ({
  className,
  ...props
}) => (
  <button
    {...props}
    className={clsx(
      'flex size-6 items-center justify-center rounded-full bg-accent2 font-semibold text-text-accent transition-all hover:bg-accent-btn focus:bg-accent-btn/80 focus:shadow-accent-btn disabled:bg-accent-btn/40',
      className,
    )}
  />
);

export const IconToggle: FC<ComponentProps<'button'> & { active: boolean }> = ({
  className,
  active,
  ...props
}) => (
  <button
    {...props}
    className={clsx(
      'flex size-6 items-center justify-center rounded-full font-semibold text-white transition-all',
      active &&
        'bg-accent2 !text-text-accent hover:bg-accent-btn focus:bg-accent-btn/80 focus:shadow-accent-btn disabled:bg-accent-btn/40',
      className,
    )}
  />
);

export const LinkButton: FC<ComponentProps<typeof Link>> = ({
  className,
  ...props
}) => (
  <Link
    {...props}
    className={clsx(
      'rounded-full bg-accent2 px-4 py-2 font-semibold text-text-accent no-underline transition-all hover:bg-accent-btn focus:bg-accent-btn/80 focus:shadow-accent-btn disabled:bg-accent-btn/40',
      className,
    )}
  />
);

export const MiniButton: FC<ComponentProps<'button'>> = ({
  className,
  ...props
}) => (
  <button
    className={clsx(
      className,
      'h-5 rounded-full bg-black/40 px-2 text-xs text-white outline-none',
    )}
    {...props}
  />
);

export const NotAButton: FC<ComponentProps<'div'>> = ({
  className,
  ...props
}) => (
  <div
    className={clsx(
      'center gap-2 rounded-full bg-black/40 px-4 py-2 font-bold text-background1',
      className,
    )}
    {...props}
  />
);
